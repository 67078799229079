.AdoptionRates {
  min-height: 100vh;
  background-color: #fff;
}

.AdoptionRates .mobile {
  display: none;
}

.AdoptionRates > * {
  animation: Show 1s;
}

.AdoptionRates > .content {
  width: 90%;
  min-height: 90vh;
  padding-top: 200px;
  margin: 0 auto;
  padding-bottom: 50px;
  position: relative;
}

.AdoptionRates .tiny-content {
  text-align: center;
}

.AdoptionRates > .content > .header {
  color: #003476;
  font-size: 2.5em;
  margin-bottom: 60px;
}

.AdoptionRates table {
  width: 100%;
  margin: 0 auto;
  background-color: transparent !important;
  border: none !important;
}

.AdoptionRates table.outer {
  margin-bottom: 70px;
  display: block;
  width: 100%;
  overflow: auto;
  border-collapse: collapse;
}

.AdoptionRates table.outer tbody tr {
  border-bottom: 1px solid rgb(190, 190, 190);
}

.AdoptionRates table th {
  color: #353f55 !important;
  background-color: #f9f9f9 !important;
  padding: 15px 20px;
}

.AdoptionRates table td {
  padding-left: 20px;
  padding-right: 20px;
}

.AdoptionRates tr.header {
  font-weight: 800;
  color: #353f55 !important;
  background-color: transparent;
}

.spinner {
  text-align: center;
  width: 100%;
}

.AdoptionRates .institutionLogoContainer {
  height: 46px;
  width: 46px;
  min-width: 46px;
  text-align: center;
}

.institutionLogo {
  height: 100%;
  width: auto;
  position: relative;
}

.AdoptionRates div.comments {
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 300px !important;
  min-width: 120px !important;
  overflow: auto;
}

.AdoptionRates div.comments .content {
  opacity: 0.8;
}

.AdoptionRates .centered.grid {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.AdoptionRates .pagination .active.item {
  color: #353f55 !important;
  border-color: #353f55 !important;
}

.AdoptionRates table:not(.inner) td:nth-child(1) {
  text-align: center;
  padding-right: 0;
  width: 30px;
}

.AdoptionRates table.inner td {
  text-align: left !important;
  padding: 10px 10px;
}

.AdoptionRates tr table.inner tr {
  box-shadow: inset 0 -1px 0 0 rgba(163, 163, 163, 0.267) !important;
}
.AdoptionRates tr table.inner tr:last-of-type {
  box-shadow: none !important;
}

.AdoptionRates .mobileHeader {
  font-weight: bold;
  display: none;
  margin-right: 15px;
  color: #353f55f0;
}

.AdoptionRates #beta-adopters {
  display: block;
  width: 99%;
  text-align: right;
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1470px) {
  .AdoptionRates > .content {
    width: 100%;
  }
}

@media only screen and (max-width: 1310px) {
  .AdoptionRates table td {
    padding-left: 2px;
    padding-right: 2px;
  }

  .AdoptionRates table th {
    padding-left: 5px;
    padding-right: 5px;
  }

  .AdoptionRates table td {
    font-size: 14px;
  }

  .AdoptionRates .strong {
    font-size: 14px;
  }
}

@media only screen and (max-width: 999px) {
  .AdoptionRates > .content {
    width: 90%;
  }

  .AdoptionRates table.outer {
    display: none;
  }

  .AdoptionRates .mobile {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .AdoptionRates .content {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 762px) {
  .AdoptionRates > .content > .header {
    color: #003476;
    font-size: 1.5em;
    margin-bottom: 40px;
    margin-top: 15%;
  }
}
