@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,800|Josefin+Sans:400,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', 'Roboto', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
@font-face {
  font-family: 'Roboto Slab';
  src: url('/fonts/Roboto_Slab/RobotoSlab-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/Josefin_Sans/JosefinSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Questrial';
  src: url('/fonts/Questrial/Questrial-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GFS Neohellenic';
  src: url('/fonts/GFS_Neohellenic/GFSNeohellenic.ttf') format('truetype');
}

@font-face {
  font-family: 'GFS Neohellenic Bold';
  src: url('/fonts/GFS_Neohellenic/GFSNeohellenicBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Share Tech Mono';
  src: url('/fonts/ShareTechMono-Regular.ttf') format('truetype');
} */

html {
  height: auto;
}

body {
  height: auto;
  scroll-behavior: smooth;
}

h2 {
  color: #353f55ad;
}

button {
  cursor: pointer;
}

.Section .button {
  max-width: 70% !important;
}

#material .description > a {
  color: #fff !important;
  background-color: #003476 !important;
  border: 0px !important;
  padding: 20px 80px !important;
  font-size: 16px !important;
  font-weight: normal !important;
  box-shadow: none;
  width: 90%;
}

#material .description > a:hover {
  background-color: #002452 !important;
}

#material .card {
  box-shadow: none;
}

#material li {
  font-weight: bold;
}

#material ul {
  list-style: none;
  margin-top: -5px;
  margin-left: 1em;
}

#material li:before {
  content: '';
  border-color: transparent #003476;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.9em;
  position: relative;
}

a {
  color: #003476;
  transition: color 0.5s;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}
a .fa-external-link-alt {
  transition: color 0.5s;
}
a:hover.fas {
  transition: color 0.5s;
}

a:hover .no-transition {
  transition: none;
}

a:hover,
a:hover .fas {
  color: #00aeef;
  transition: color 0.5s;
}

#Landing,
.Page {
  width: 100%;
  margin: 0 0;
  height: 100vh;
  background-color: white;
  position: relative;
  top: 0;
}

.Page .container-header {
  margin-bottom: 50px !important;
}

.Page .content {
  padding-left: 40px;
  padding-right: 40px;
}

#App.redirect-ready .Section {
  height: 100vh;
  box-shadow: none !important;
}

.Section {
  z-index: 1;
  display: inline-block;
  width: 50%;
  height: calc(100vh - 50px);
  position: static;
  box-shadow: inset 0 -7px 7px -7px #aeaeae;
}

.Section.full-width .Homecard {
  opacity: 0;
  transition: opacity 0.2s;
}

.Faded {
  opacity: 0.6;
  transition: opacity 0.6s ease-in;
}

.strong {
  font-weight: 800;
}

.with-description {
  border-bottom: 1px dotted rgb(100, 100, 100);
}

.Section-left {
  /* Rust   background-color: #B56357;*/
  background-color: #f6f6f6;
}

.Section-right {
  /* Pine foresty background-color: #86BC96;*/

  background-color: #e4e4e4;
  background-color: #e7e7e7;
}

.More {
  display: inline-block;
  z-index: 2;
  width: 100%;
  background-color: #fff;
  font-size: 18px;
  text-align: center;
  padding-top: 20px;
  bottom: 0;
  position: absolute;
}

.More:hover {
  cursor: pointer;
  height: 80px;
  padding-top: 20px;
  font-size: 20px;
  transition: height 0.4s ease-in-out, font-size 0.4s ease-in,
    padding-top 0.4s ease-in-out;
}

.App #logout {
  margin-top: 5px;
}

.compact {
  padding: 0 !important;
  position: relative !important;
}

.Homecard {
  position: relative;
  top: 23% !important;
}

.Homecard svg {
  height: 34vh;
}

.Cardicon {
  font-size: 76px;
  color: #2f2f2f;
}

.Description {
  margin: 0 auto;
  min-width: 50%;
  max-width: 400px;
  padding-bottom: 15px;
}

.Description h2,
h1 {
  color: #000;
  padding: 0 0 0 0;
  margin-bottom: 0;
}

.Description .fas {
  color: #888;
  color: #00aeef;
  color: #019aeb;
  font-size: 150px;
  margin-top: 40px;
}

.Description .text-area p {
  color: #000;
}

.Description .text-area {
  height: 82px;
  display: table;
  table-layout: fixed;
  margin: auto;
}

.Description p {
  color: white;
  font-size: 18px;
  display: table-cell;
  vertical-align: middle;
}

input,
button {
  font-family: 'Segoe UI', 'Roboto', sans-serif;
}

.block {
  display: block;
}

.custom-hidden {
  opacity: 0;
  transition: opacity 0.3s;
}

.Help .Help-Content h1 {
  color: #000;
  font-size: 2.7em;
  font-weight: normal;
}

.Help,
.More {
  background-color: #fff;
}

.Help .Help-Content i,
#description .card .header {
  color: #003476 !important;
}

.Help {
  min-height: 100vh;
  background-color: #fff;
  /* padding-top: 20px; */
  padding-bottom: 0px;
}

.Help .Help-Content {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 4px;
}

.Help .nav {
  grid-area: hnav;
  background-color: #f9f9f9;
  margin-left: 10%;
  padding-top: 20px;
  width: 100%;
  padding-left: 15%;
  position: relative;
}

.Help p {
  text-indent: 0px;
  font-size: 16px;
}

.content-section {
  margin-bottom: 100px;
}

.split {
  -webkit-column-count: 2;
          column-count: 2;
  grid-column-gap: 70px;
  -webkit-column-gap: 70px;
          column-gap: 70px;
  -webkit-column-rule: 2px solid lightblue;
          column-rule: 2px solid lightblue;
}

.Help-Gray {
  background-color: #f1f1f1;
}

.content-section-paragraphs {
  margin-bottom: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: left;
}

.content-section-paragraphs > h2 {
  display: inline-block;
}

#Nav-content {
  width: 250px;
  z-index: 2;
  transition: all 0.5s;
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
}

#Nav-content h2 {
  color: #000;
}

#Nav-content ul {
  list-style: none;
  padding-left: 10px;
  margin-top: 40px;
}

#Nav-content li {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  opacity: 0.9;
  padding-bottom: 20px;
}

#Nav-content li:nth-child(1),
#Nav-content li:nth-child(2) {
  border-bottom: 1px solid rgba(167, 179, 165, 0.692);
}

.Help .Help-Content .ui.cards {
  width: 95%;
  margin: 0 auto;
  margin-top: 40px !important;
}

.ui.card,
.ui.cards > .card {
  background: transparent !important;
  margin: auto;
  min-height: 340px;
  border-radius: 0.2rem !important;
  cursor: default !important;
  width: 25%;
  min-width: 200px;
}

.ui.cards > .card:hover {
  -webkit-transform: initial !important;
          transform: initial !important;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
}

.ui.card,
.ui.cards > .card .header {
  text-align: center;
  color: #353f55 !important;
  font-family: 'Roboto Slab', sans-serif !important;
}

.ui.card,
.ui.cards > .card .image {
  background: transparent !important;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

.ui.card,
.ui.cards > .card .description {
  padding: 0 24px;
  font-size: 15px;
}

.Help .Help-Content i {
  color: #353f55d5;
}

.fancy {
  color: #fff;
  background-color: #003476;
  border: 0px;
  padding: 20px 40px;
  font-size: 18px;
}

.fancy.tiny {
  font-size: 12px;
  padding: 4px 6px;
}

.fancy.disabled,
.fancy:disabled,
.fancy.disabled .fas,
.fancy:disabled .fas {
  background-color: rgb(175, 175, 175);
  color: rgb(219, 219, 219);
  pointer-events: none;
}

.fancy .fas {
  color: #fff;
}

.fancy:not(.disabled):hover {
  background-color: #002452;
}

.fancy:hover .animatable {
  padding-left: 6px;
  transition: padding 0.1s ease-in-out;
}

.center {
  text-align: center;
}

#organization-link,
#graduate-link {
  display: none;
  background-color: transparent;
}

#graduate-link:hover .fas,
#organization-link:hover .fas {
  color: #353f55 !important;
}

#org-more {
  display: none;
}

#e {
  color: #019aeb !important;
  color: #c3c3c3 !important;
  color: #ffffff !important;
}

#developer-header {
  font-family: 'Share Tech Mono', monospace;
  font-size: 42px;
}

.ExternalRedirect {
  display: flex;
}

.ExternalRedirect #spinner {
  display: block;
  width: 100%;
  text-align: center;
  margin: auto;
}

.genericError h3 {
  color: #b56357;
  margin-top: 7vh;
}

.genericError .buttons {
  margin-top: 50px !important;
}

.genericError .buttons .button {
  width: 204px;
}

.Help #nav-content h2,
.Help #nav-content hr {
  z-index: 2;
}

.presentationDownload {
  margin-left: 10px;
}

.Help .doc-links.ui.cards .card {
  margin: 0;
  width: 300px;
  margin-right: 35px;
  padding: 0;
  padding-bottom: 15px;
  min-height: 0;
  margin-top: 10px;
}

.Help .doc-links.ui.cards .card .image {
  height: 250px;
}

.Help .doc-links.ui.cards .card .description {
  padding: 0;
  padding-top: 20px;
  text-align: center;
}

.Help .doc-links.ui.cards .card img {
  height: auto;
  width: 200px;
  margin: 0 auto;
}

#newspaper-image,
#book-image,
#demo-image,
#presentation-image {
  padding-top: 25px !important;
  height: 170px !important;
  width: auto !important;
  margin: 0 auto;
}

.doc-links .card:not(.press) a {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 0;
  display: inline;
  border-radius: 5px;
  min-width: 180px;
}

.press.card {
  margin: 0;
  width: 300px;
  margin-right: 35px;
  padding: 0;
  padding-bottom: 15px;
  min-height: 0;
  text-align: left;
}

.press.card ul {
  text-align: left;
  padding-left: 50px;
}

@media only screen and (max-width: 1497px) {
  .Help .ui.cards .card {
    width: 30%;
    min-height: 365px;
  }
}

@media only screen and (max-width: 1297px) {
  .Help .ui.cards .card {
    margin-bottom: 20px;
    width: 90%;
    max-width: 400px;
    min-height: 327px;
  }

  .Section {
    padding-bottom: 2%;
  }
}

@media (-webkit-max-device-pixel-ratio: 2.0833333333333335) and (max-width: 500px), (max-resolution: 200dpi) and (max-width: 500px) {
  .Homecard:nth-child(2) {
    margin-top: 0 !important;
  }

  .Homecard {
    width: 95% !important;
  }

  .Description h2 {
    font-size: 16px !important;
    margin-top: 3px;
  }

  .Description p {
    font-size: 15px !important;
  }

  .Header h1 {
    font-size: 27px !important;
  }
}

@media only screen and (max-width: 782px) {
  .Description .fas {
    font-size: 110px;
  }

  .img-logo {
    height: 65px;
    margin-right: -10px;
  }

  #img-govgr {
    width: 100px;
  }

  #graduate {
    margin-top: 60px !important;
  }

  .App {
    position: relative;
    width: 100vw;
    overflow-x: hidden;
  }

  .Section {
    display: block;
    width: 100% !important;
    height: calc(100vh - 50px);
    position: relative;
  }

  .Homecard .compact.row {
    width: 100vw;
  }

  .Section .compact.row #organization-link {
    position: absolute !important;
    bottom: 50px;
    width: 100vw !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .Section .compact.row #graduate-link {
    position: absolute !important;
    top: 10px;
    width: 100vw !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .Homecard svg {
    min-height: 150px;
    height: 29vh;
  }

  .Section:nth-child(2) {
    display: block;
    width: 100% !important;
    height: calc(100vh - 200px);
  }

  .Section:nth-child(2) .Homecard {
    top: 15% !important;
  }

  #More {
    display: block;
    background-color: rgb(252, 252, 252);
    position: absolute;
    top: 0;
    bottom: auto;
  }

  .More:hover {
    display: inline-block;
    z-index: 2;
    width: 100%;
    height: 50px;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: 'Roboto Slab', serif;
    text-align: center;
    padding-top: 5px;
    position: absolute;
    color: #353f55;
  }

  .More:hover > *,
  .More:hover .fas {
    color: #353f55 !important;
  }

  .Help {
    min-height: 100vh;
    background-color: rgb(252, 252, 252);
    display: block;
    padding-top: 80px;
    padding-bottom: 90px;
    z-index: 1;
    position: relative;
  }

  #Nav-content {
    position: static;
    width: 80%;
    padding-left: 20px;
  }
  .Help .nav {
    margin-top: 20px;
    padding-left: 0;
  }

  .Help .Help-Content {
    padding-right: 30px;
    padding-left: 30px;
  }

  .Help .content-section {
    margin-bottom: 50px;
  }

  #Landing {
    height: auto;
  }

  .Homecard {
    width: 85%;
    margin: 0 auto;
    top: 15% !important ;
  }

  .Homecard .Description p {
    font-size: 17px;
  }

  #organization-link,
  #graduate-link {
    display: block;
  }

  .Section {
    min-height: 520px !important;
  }

  .Section .Description {
    max-width: 500px;
  }

  .Section .compact.row #organization-link {
    margin-bottom: -70px !important;
  }
}

@media only screen and (max-width: 670px) {
  #organization-link,
  #graduate-link {
    display: block;
  }

  .Help .nav {
    padding-left: 0;
  }

  .Homecard {
    width: 85%;
    margin: 0 auto;
  }

  .Homecard .Description p {
    font-size: 14px;
  }
  .container-header {
    position: relative;
    top: 50px;
    font-size: 20px;
    text-align: center;
    padding: 15px;
  }

  #eDiplomasLogo .logo-text {
    font-size: 20px;
    padding: 0;
    margin: 0;
  }

  .split {
    -webkit-column-count: 2;
            column-count: 2;
    grid-column-gap: 70px;
    -webkit-column-gap: 70px;
            column-gap: 70px;
    -webkit-column-rule: 2px solid lightblue;
            column-rule: 2px solid lightblue;
  }
}

@media only screen and (max-width: 350px) and (max-height: 500px) {
  .Section:nth-child(1) {
    display: block;
    width: 100% !important;
    height: 450px;
  }

  .Section:nth-child(2) {
    display: block;
    width: 100% !important;
    height: 450px;
  }
}

@media only screen and (max-width: 688px) {
  .Description h2 {
    font-size: 17px !important;
  }

  .Description p {
    font-size: 14px !important;
  }
}

@media only screen and (max-height: 650px) and (min-width: 782px) {
  .Homecard:nth-child(2) {
    margin-top: 0 !important;
  }

  .Homecard {
    width: 95% !important;
  }

  #Landing,
  .Section {
    height: 630px;
  }

  /* .Section {
    display: block;
  } */

  .More {
    top: 630px;
  }

  .Help {
    margin-top: 0;
    padding-top: 80px;
  }

  .Description h2 {
    font-size: 16px !important;
  }

  .Description p {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 590px) {
  .genericError .buttons {
    margin-top: 30px !important;
  }

  .genericError .buttons .button {
    margin: 0 auto !important;
  }

  .genericError .buttons .button,
  .genericError .buttons .back-link {
    display: block;
  }

  .genericError .buttons .back-link {
    position: relative;
    top: 80px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 355px) {
  /*iphone 5 landing cards*/

  .Help .Help-Content {
    padding-right: 35px;
    padding-left: 25px;
  }
}

@media only screen and (min-width: 355px) and (max-width: 365px) {
  /*specific fix for landing cards edge case */

  .Help .Help-Content {
    padding-right: 35px;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 365px) and (max-width: 380px) {
  /*specific fix for landing cards edge case */

  .Help .Help-Content {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media only screen and (max-width: 315px) and (max-height: 517px) and (min-height: 435px) {
  .Homecard {
    margin: 0 4px;
  }

  .Help .Help-Content {
    padding-left: 20px;
    padding-right: 20px;
    width: 90%;
  }

  .Section {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 470px !important;
  }

  .Homecard {
    width: 100vw !important;
    margin-left: 0;
    margin-right: 0;
  }

  .Description {
    width: calc(100vw - 6px);
    padding-left: 5px;
    padding-right: 5px;
  }

  .Section p {
    width: 100%;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}

@media only screen and (max-width: 480px) {
  /* medium phones */
  .content-section {
    padding-top: 1rem;
  }

  .content-section-paragraphs {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
  }

  .split {
    -webkit-column-count: 1;
            column-count: 1;
    grid-column-gap: 70px;
    -webkit-column-gap: 70px;
            column-gap: 70px;
    -webkit-column-rule: 2px solid lightblue;
            column-rule: 2px solid lightblue;
  }

  .Page .content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 480px) and (max-height: 850px) {
  /* usual phone screens */
  #Nav-content {
    position: static;
    top: calc(450px + 180px);
    width: 80%;
    padding-left: 20px;
  }

  .container-header {
    position: relative;
    top: 50px;
    font-size: 20px;
    text-align: center;
    padding: 15px;
  }

  .Homecard .Description p {
    font-size: 17px;
  }
  #organization-link {
    margin-top: 0px;
  }

  #organization-link,
  #graduate-link {
    display: block;
  }
}

@media only screen and (max-width: 480px) and (max-height: 950px) {
  /* usual phone screens */

  .Homecard {
    height: 95% !important;
  }

  .Section:nth-child(1) {
    display: block;
    width: 100% !important;
    margin-top: 150px;
    /* height: 470px; */
    min-height: 75vh !important;
  }

  .Section-right {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
  }

  .Section-left {
    padding-bottom: 90px !important;
    padding-top: 20px !important;
  }

  .Section .Description {
    max-width: 300px;
  }

  .Help {
    min-height: 517px;
    display: block;
    padding-top: 70px;
    padding-bottom: 90px;
    z-index: 1;
  }

  #img-govgr {
    right: 5px;
    top: 25px;
  }

  .Header h1 {
    padding-bottom: 0;
  }

  #Nav-content {
    position: static;
    top: calc(450px + 180px);
    width: 80%;
    padding-left: 20px;
  }

  .container-header {
    position: relative;
    top: 50px;
    font-size: 20px;
    text-align: center;
    padding: 15px;
  }

  .Homecard .Description p {
    font-size: 17px;
  }
  #organization-link {
    margin-top: 0px;
  }

  #organization-link,
  #graduate-link {
    display: block;
  }
}

@media only screen and (max-width: 315px) {
  .Homecard svg {
    width: 150px;
  }
}

@media only screen and (min-width: 670) and (max-width: 870px) {
  /* rotation */

  .Description p {
    padding-left: 25px !important;
  }
}

.Footer {
  background-color: #003476;
  position: relative;
  height: 250px;
  padding: 30px 30px;
  z-index: 1;
  height: auto;
  position: relative;
  padding-right: 20px;
  display: grid;
  grid-template-columns: 1fr 50px;
  /* padding-left: 100px; */
}

.Footer a {
  color: #fafafa;
  transition: color 0.5s;
  font-weight: normal !important;
}

.Footer a:hover {
  color: #00aeef;
  transition: color 0.5s;
}

.Footer li a {
  font-size: 16px;
}

.Footer .left,
.Footer .right {
  float: unset !important;
}

.Footer .left.content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-left: 40px;
}

.Footer .left.content > div {
  display: inline-block;
  position: relative;
  margin-right: 5%;
  margin-bottom: 20px;
  vertical-align: top;
  transition: all 0.9s;
}

.Footer .right.content {
  position: absolute;
  float: right;
  margin-top: 15px;
  right: 0;
  bottom: 40px;
  max-width: 200px;
  margin-left: 40px;
}

.Footer .copyright-area {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
}

.Footer #contact li:last-child {
  border: 0;
}

.Footer .left.content ul {
  list-style: none;
  padding-left: 0;
}

.Footer .left.content ul {
  margin-left: 0;
}

.Footer .right.content {
  display: inline-block;
  margin-right: 40px;
  margin-top: 300px;
  color: rgb(267, 264, 237);
}

.Footer .left.content ul > li {
  margin-bottom: 25px;
  color: rgb(267, 264, 237);
  font-size: 14px;
  border-bottom: 1px solid rgb(267, 264, 237);
  padding-bottom: 6px;
}

.Footer .content .header {
  color: rgb(267, 264, 237);
}

.Footer .left.content .header {
  margin-bottom: 30px;
  width: 391px;
}

.Footer .subheader {
  margin-right: 10px;
  word-break: break-all;
}

.Footer .fas {
  color: rgb(267, 264, 237) !important;
}

.Footer #contact .header,
.Footer #info .header,
.Footer #services .header {
  margin-bottom: 15px;
}

.Footer #info a,
.Footer #services a {
  font-weight: 900;
}

#FooterLogo {
  height: auto;
  width: 110px;
}

#website-url {
  margin-top: 15px;
}

.Footer h3.header {
  font-size: 14px !important;
  font-weight: bold;
}

@media only screen and (max-width: 1365px) {
  .Footer .left.content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 40px;
  }

  .Footer .left.content div {
    margin-bottom: 0;
    max-width: 391px;
  }

  .Footer .copyright-area {
    top: 20px;
    position: relative;
  }
}

@media only screen and (max-width: 1500px) {
  .Footer {
    grid-template-columns: 1fr;
  }

  .Footer .right.content {
    position: relative;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 782px) {
  .Footer .left.content ul {
    width: 90%;
  }

  .Footer > * {
    padding-right: 20px;
  }

  .Footer h3.header {
    width: 70vw !important;
  }

  .Footer .left.content,
  .Footer .right.content {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1776px) {
  /*fixes smaller screens footer bug */

  .Footer {
    height: auto !important;
  }
}

.landing-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  margin-top: 50px;
}

.landing-cards > .card {
  border: 2px solid #cfcfcf;
  border-radius: 15px;
  padding: 4rem;
  padding-bottom: 1rem;
  padding-top: 2rem;
  text-align: center;
  font-size: 15px;
}

.landing-cards > .card h3 {
  font-size: 20px;
  color: #003476;
}

.landing-cards > .card p {
  text-align: left;
}

#description > p {
  font-size: 17px;
  color: #3a3a3a;
}

.links-section ul {
  margin-left: 0;
  padding-left: 18px;
}

.info-cards,
.links-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 2rem;
  padding-top: 1rem;
}

.links-container {
  margin-bottom: 2%;
}

.info-cards > .card {
  border: 0.5px solid #cfcfcf;
  border-radius: 25px;
  padding: 1rem;
  padding-bottom: 4rem;
  padding-top: 2rem;
  text-align: center;
  font-size: 15px;
}

.info-cards > .card > .content > ul {
  text-align: left;
}

.info-cards > .card > .content > .description {
  margin-top: 60px;
}

#description .card {
  box-shadow: none;
}

#priviledge-dates {
  color: #003476;
}

#priviledge-dates #priviledge-text {
  padding-left: 10px;
}

.Help.hidden,
#Landing .compact.row.hidden,
.App.redirect-ready .Footer {
  opacity: 1 !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
}

/* MEDIA */

@media only screen and (max-width: 355px) {
  /* iPhone 5 */

  .landing-cards > .card {
    border: 2px solid #cfcfcf;
    border-radius: 15px;
    padding: 4rem;
    padding-bottom: 1rem;
    padding-top: 2rem;
    padding-right: 1rem;
    text-align: left;
    font-size: 15px;
    width: 60%;
  }
}

@media only screen and (max-width: 782px) {
  .Help {
    padding-top: 72px !important;
  }

  #organization-link,
  #graduate-link {
    display: block;
  }

  .More {
    top: 517px;
  }
}

@media only screen and (max-width: 1080px) {
  /* for all media */

  .info-cards > .card {
    padding-bottom: 2rem;
  }
}

.Section-left .Homecard svg:hover .thing {
  -webkit-animation: move-thing 1s 1;
          animation: move-thing 1s 1;
}

#briefcase {
  position: relative;
}

@-webkit-keyframes scale-corner {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  50% {
    -webkit-transform: scaleY(1.1) translateY(-23px);
            transform: scaleY(1.1) translateY(-23px);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@keyframes scale-corner {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  50% {
    -webkit-transform: scaleY(1.1) translateY(-23px);
            transform: scaleY(1.1) translateY(-23px);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@-webkit-keyframes cap-string {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes cap-string {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes move-thing {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(1.5deg);
            transform: rotate(1.5deg);
  }
}

@keyframes move-thing {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(1.5deg);
            transform: rotate(1.5deg);
  }
}

@-webkit-keyframes open-lid {
  0% {
    -webkit-transform: rotate3d(0, 0, 0, 0) translateY(0);
            transform: rotate3d(0, 0, 0, 0) translateY(0);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 0, 13deg) translateY(-5px);
            transform: rotate3d(0, 0, 0, 13deg) translateY(-5px);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 0, 0deg) translateY(0);
            transform: rotate3d(0, 0, 0, 0deg) translateY(0);
  }
}

@keyframes open-lid {
  0% {
    -webkit-transform: rotate3d(0, 0, 0, 0) translateY(0);
            transform: rotate3d(0, 0, 0, 0) translateY(0);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 0, 13deg) translateY(-5px);
            transform: rotate3d(0, 0, 0, 13deg) translateY(-5px);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 0, 0deg) translateY(0);
            transform: rotate3d(0, 0, 0, 0deg) translateY(0);
  }
}

#NewsReel {
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: #e4e6f7;
  flex-wrap: wrap;
  border-radius: 15px;
  /* box-shadow: 1px 1px 0 1px rgb(137, 135, 150); */
}

#NewsReel .Main {
  display: block;
  flex: 1 0 65%;
  text-align: center;
}

#NewsReel .Main iframe {
  padding-top: 12px;
  padding-bottom: 12px;
  width: 80%;
  height: auto;
  aspect-ratio: 16 / 9;
}

h2.news {
  margin-top: 20px;
}

#NewsReel .Side {
  flex: 1 0 35%;
}

#NewsReel .Side ul {
  display: block;
  /* background-color: #7b8bc0; */
  background-color: #9aa8d3;
  margin: 0;
  height: 100%;
  list-style: none;
  padding-left: 0;
  color: rgb(43, 43, 43);
  font-weight: bold;
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 15px;
  border-left: 2px solid #dadbeb;
}

#NewsReel .Side li {
  padding-top: 10px;
  padding-bottom: 10px;
  /* border-bottom: 1px dashed rgb(78, 78, 78);; */
  /* box-shadow: 2px 2px 0 1px rgb(61, 59, 88); */
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  transition: all 0.1s;
}

#NewsReel .Side li:first-of-type {
  border-top-right-radius: 15px;
}

#NewsReel .Side li:hover {
  cursor: pointer;
  background-color: #e4e6f7;
  /* border-left: 3px solid #7b8bc0; */
}

#NewsReel .Side li span {
  padding-left: 10px;
}

#NewsReel .Side li img {
  display: inline;
  height: 80px;
  width: auto;
}

#NewsReel .Side li.active {
  background-color: #e4e6f7;
}

/* .Video:first-of-type {
    box-shadow: 1px 0 0 0 #cfcfcf;
  } */

.Video h4 {
  /* background-color: #e2e2e2; */
  width: auto;
  display: block;
  padding: 5px;
  color: #505364;
  font-size: 15px;
}

#NewsReel .TextEntry {
  text-align: left;
  display: block;
  padding-left: 40px;
}

@media only screen and (max-width: 768px) {
  #NewsReel {
    border-radius: 0;
  }

  #NewsReel .Main iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    padding-top: 0;
  }

  #NewsReel .Side ul {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  #NewsReel .Side li:first-of-type {
    border-top-right-radius: 0;
  }
}

.Graduate .ClientSelect .dropdown {
  width: 50%;
  border-radius: 0;
}

.Graduate .ClientSelect .dropdown .icon {
  border-radius: 0;
  border: none;
}

.Graduate .ClientSelect .dropdown .menu {
  border: 2px solid rgba(34, 36, 38, 0.15);
  width: calc(100%);
  margin-left: -2px;
  border-radius: 0;
  box-sizing: content-box;
}

.Graduate .ui.selection.dropdown .menu {
  min-width: 100%;
}

.ClientSelect .selected-client {
  margin-top: 32px !important;
}

.ClientSelect .client-name {
  margin-left: 20px;
  color: #a7b3a3;
  font-size: 1.3em;
}

.ClientSelect .client-name.empty {
  border-bottom: 1px solid #a7b3a3;
  min-width: 27% !important;
  display: inline-block;
  height: 21px;
}

@media only screen and (max-width: 782px) {
  .Graduate .ClientSelect .dropdown {
    width: 90%;
    max-width: 300px;
  }
}

@media only screen and (max-width: 329px) {
  .Graduate .ClientSelect .dropdown {
    width: 100%;
  }

  .ClientSelect .client-name.empty {
    min-width: 60% !important;
  }
}

.Graduate .CompletedProcess .step-description {
  padding-bottom: 25px;
}

.CompletedProcess .hidden {
  display: none;
}

.CompletedProcess #token-area {
  text-align: center;
  width: 50%;
  display: block;
  margin: 0 auto;
}

.CompletedProcess #token-area p {
  word-break: break-all;
}

.CompletedProcess .token-container {
  font-size: 20px;
  background-color: rgb(224, 224, 224);
  display: flex;
  transition: border 0.5s;
  margin: 0 auto;
  text-align: left;
  position: relative;
  max-width: 450px;
  min-height: 1em;
}

.CompletedProcess .token-container .token-text {
  flex: auto;
  padding: 15px;
  overflow: hidden;
  white-space: nowrap;
  background-color: rgb(224, 224, 224);
  border: 0;
  font-size: 16px;
  color: black;
  outline: none;
}
.CompletedProcess #token-area .or {
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 22px;
}

.CompletedProcess .token-container .token-action {
  padding: 15px 20px;
  border-top: 0px;
  background-color: rgb(53, 63, 85);
  color: rgb(252, 252, 246);
  transition: all 0.25s;
  cursor: pointer;
}

.CompletedProcess .token-container .token-action:not(:first-child) {
  margin-left: 1px;
}

.CompletedProcess .token-container .token-action:hover {
  transition: all 0.5s;
  background-color: rgba(53, 63, 85, 0.7);
}

.CompletedProcess a:not(.token-action) {
  color: #003476;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  transition: color 0.5s, border 0.5s;
}

.CompletedProcess a .fas {
  color: #003476 !important;
}

.CompletedProcess a:not(.token-action):hover {
  color: #00aeef;
  border-bottom: 1px solid #00aeef;
  transition: color 0.5s, border 0.5s;
}

.CompletedProcess .token-container .mailTemplate:hover {
  border-bottom: 0;
  cursor: default;
}

.CompletedProcess a:hover .fas {
  color: #00aeef !important;
}

.CompletedProcess .mailTemplate {
  border-bottom: 0;
}

.CompletedProcess .ui.message ul > li {
  margin-bottom: 15px;
}

.CompletedProcess #duration-container {
  margin-top: 50px;
  margin-bottom: 6%;
}

.CompletedProcess #duration-container h4 {
  width: 30%;
  border-bottom: 1px solid #353f55;
  padding-bottom: 12px;
}

.CompletedProcess .ui.message .header {
  color: #353f55;
  margin-bottom: 15px;
}

.CompletedProcess .ui.message ul {
  margin-top: 25px;
  padding-left: 15px;
  opacity: 0.9;
}

.CompletedProcess #duration-results {
  display: flex;
  align-content: center;
}
.CompletedProcess #duration-results span {
  align-self: center;
}

.CompletedProcess #duration {
  margin-left: 10px;
  font-size: 18px;
  display: inline-block;
}

.CompletedProcess .step-description {
  padding-bottom: 0;
}

.CompletedProcess #copiedNotification {
  color: rgba(0, 128, 0, 0.76);
  text-align: center;
  height: 10px;
  opacity: 0;
  margin-top: 10px;
}

.CompletedProcess #copiedNotification.show {
  -webkit-animation: Show 0.3s;
          animation: Show 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.CompletedProcess #copiedNotification.disappeared {
  opacity: 1;
  -webkit-animation: Disappear 1.8s;
          animation: Disappear 1.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes Disappear {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Disappear {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1180px) {
  .CompletedProcess #token-area {
    width: 60%;
  }

  .CompletedProcess .token-container .token-text {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .CompletedProcess #token-area {
    width: 100%;
    margin: 0 auto;
  }

  .CompletedProcess .token-text {
    font-size: 18px;
  }

  .CompletedProcess .token-wrapper {
    font-size: 15px;
    padding: 14px 14px;
    width: calc(100% - 30px);
    overflow: auto;
    display: inline-block;
  }

  .CompletedProcess #token {
    padding: 0 10px;
  }

  .CompletedProcess .token-container .copy,
  .CompletedProcess .token-container .copy:hover,
  .CompletedProcess .token-container .mailTemplate,
  .CompletedProcess .token-container .mailTemplate:hover {
    padding: 12px 12px;
  }
}

@media only screen and (max-width: 368px) {
  .CompletedProcess #token-area {
    width: 100%;
  }

  .CompletedProcess .token-container {
    justify-content: center;
    align-items: center;
  }

  .CompletedProcess .token-container .token-text {
    font-size: 15px;
    padding-left: 5px;
    padding-right: 0;
  }
}


.Terms #term-list {
  width: auto;
  max-width: 400px;
  margin: 0 auto;
  display: inline-block;
  text-align: left;
  list-style: disclosure-closed;
  margin-top: 40px;
}

.Terms #term-list li {
  margin-bottom: 16px;
}

.Terms #term-list .fas {
  color: #000000;
  margin-right: 4px;
}

.Terms .buttons {
  margin-top: 30px;
}

@media only screen and (max-width: 329px) {
  .Graduate .Terms .step-description {
    padding-bottom: 20px;
  }
}


.Steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 0;
  counter-reset: step-counter;
}

.Steps .step {
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  counter-increment: step-counter;
  padding: 1rem;
  font-size: 14px;
  position: relative;
}

.Steps .step:not(:last-child) {
  border-right: 0;
}

.Steps .step:before {
  content: counters(step-counter, '.');
  color: rgb(0, 52, 118);
  font-weight: bold;
  padding-right: 10px;
  font-size: 19px;
}

.Steps .step .content {
  display: inline-block;
  z-index: 1;
}

.Steps .step.step:not(:last-child).active .content:after {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-width: 0 1px 1px 0;
  background-color: rgb(224, 224, 224);
  right: -1px;
  top: 50%;
  position: absolute;
  content: '';
  height: 1.14285714em;
  width: 1.14285714em;
  -webkit-transform: translateY(-50%) translateX(50%) rotate(-45deg);
          transform: translateY(-50%) translateX(50%) rotate(-45deg);
  display: block;
  z-index: 2;
}

.Steps .step.active {
  background-color: rgb(224, 224, 224);
  font-weight: bold;
}

.Steps .content {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 1651px) {
  .Page > .content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1215px) {
  .Steps .step:before {
    display: block;
    padding-right: 0;
  }
}

@media only screen and (max-width: 480px) {
  .Steps .content {
    font-size: 0.9em;
    word-break: normal;
  }
}

@media only screen and (max-width: 300px) {
  .Steps {
    grid-template-columns: 1fr;
  }

  .Steps .step:not(:last-child) {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 0;
  }

  .Steps .step.step:not(:last-child).active .content:after {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-width: 0 1px 1px 0;
    background-color: rgb(224, 224, 224);
    right: 50%;
    top: 100%;
    position: absolute;
    content: '';
    height: 1.14285714em;
    width: 1.14285714em;
    -webkit-transform: translateY(-50%) translateX(50%) rotate(45deg);
            transform: translateY(-50%) translateX(50%) rotate(45deg);
    display: block;
    z-index: 2;
  }
}

.Graduate.Page {
  height: auto;
  position: absolute;
  background-color: #fafafa;
}

.Graduate .container {
  background-color: #fff;
  margin-top: 160px;
  min-height: 60vh;
  width: 55vw;
  margin-bottom: 80px;
  padding-top: 30px;
  padding-bottom: 80px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-animation: Show 0.4s;
          animation: Show 0.4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  position: static;
  margin: 160px auto;
}

.Organisation .container > * {
  -webkit-animation: Show 0.4s;
          animation: Show 0.4s;
}

.Graduate .Header {
  background-color: #003476;
  position: absolute;
}

.ClientSelect .client-name {
  color: #555;
}

.Graduate .steps-wrapper {
  margin-bottom: 40px;
}

.Graduate .ui.steps {
  text-align: left !important;
  width: 100%;
  border-radius: 0.1rem !important;
}

.Graduate .ui.steps .step {
  width: 30%;
}

.Graduate .ui.steps .content {
  color: #353f55;
}

.Graduate .ui.steps .step:before {
  color: #a7b3a3 !important;
  padding-bottom: 4px;
}

.Graduate .ui.steps .step.active,
.Graduate .ui.steps .step.active:after {
  background-color: #f5f1f1 !important;
}

.Graduate .step-description {
  padding-bottom: 25px;
}

.Graduate .container-header {
  margin-bottom: 50px;
}

.Graduate h2 {
  color: #003476;
}

.Graduate .buttons {
  margin: 0 auto;
  text-align: center;
  margin-top: 70px;
}

.Graduate .buttons button {
  width: auto;
}

.Graduate .buttons button:nth-child(1) {
  margin-right: 14%;
}

.Graduate .buttons button:nth-child(2) {
  margin-left: 14%;
}

.Graduate .fas {
  color: #003476;
}

.Graduate .back-link {
  font-size: 17px;
}

.Graduate .Menu .selection {
  background-image: linear-gradient(
    #fafafa,
    #fafafa 50%,
    hsla(0, 0%, 85.5%, 0.288) 0,
    hsla(0, 0%, 85.5%, 0.288)
  ) !important;
}

.fancy .fas {
  color: #fff;
}

@media only screen and (max-width: 1048px) {
  .Graduate .ui.container {
    width: 80vw;
  }
}

@media only screen and (max-width: 500px) {
  .Graduate .ui.container {
    margin-top: 90px;
  }
}

@media only screen and (max-width: 480px) {
  .Graduate.Page {
    background-color: #fff;
  }
  .Graduate .container {
    background-color: #fff;
    margin-top: 160px;
    min-height: 83vh;
    width: 100vw;
    margin-bottom: 80px;
    padding-top: 30px;
    padding-bottom: 80px;
    box-shadow: none;
    opacity: 0;
    -webkit-animation: Show 0.4s;
            animation: Show 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    position: static;
    margin: 100px auto;
  }
}

@media only screen and (min-width: 480px) and (max-width: 900px) {
  .Graduate.Page {
    background-color: #fff;
  }
  .Graduate .container {
    background-color: #fff;
    margin-top: 160px;
    min-height: 83vh;
    width: 100vw;
    margin-bottom: 80px;
    padding-top: 30px;
    padding-bottom: 80px;
    box-shadow: none;
    opacity: 0;
    -webkit-animation: Show 0.4s;
            animation: Show 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    position: static;
    margin: 100px auto;
  }
}

@media only screen and (max-width: 329px) {
  .Graduate .ui.container {
    width: 90vw !important;
  }

  .Graduate .buttons {
    margin-top: 40px;
  }

  .Graduate .buttons .button,
  .Graduate .buttons .back-link {
    display: block;
  }

  .Graduate .buttons .back-link {
    position: relative;
    top: 120px;
  }

  .Graduate .buttons button:nth-child(2) {
    display: block;
    margin: 0 auto;
  }
}

.Organisation .menu-button {
  padding: 0px;
}

.Organisation .menu-button .fas {
  color: #ffffff !important;
}

.Organisation .menu-button:hover .fas {
  color: #00aeef !important;
}

.Organisation.Page {
  height: auto;
  position: absolute;
  background-color: #fafafa;
}

.Organisation .container {
  background-color: #fff;
  margin-top: 160px;
  min-height: 83vh;
  width: 55vw;
  margin-bottom: 80px;
  padding-top: 30px;
  padding-bottom: 80px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-animation: Show 0.4s;
          animation: Show 0.4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  position: static;
  margin: 160px auto;
}

.Organisation .ui.container > * {
  -webkit-animation: Show 0.4s;
          animation: Show 0.4s;
}

.Organisation .TokenEntry,
.Organisation .Results,
.Organisation .Terms,
.Organisation .Logout,
.Organisation .error-description,
.Organisation .Check .description {
  padding-left: 40px;
  padding-right: 40px;
}

.Organisation .Header {
  background-color: #003476;
  position: absolute;
}

.Organisation .header {
  color: #003476 !important;
}

.Check > .description > h3 {
  color: #003476 !important;
}

.link-menu .fa-compass {
  color: #00aeef !important;
}

.Organisation .Steps-wrapper {
  text-align: center;
  margin-bottom: 40px;
}

.Organisation .ui.steps {
  text-align: left !important;
  width: 100%;
  border-radius: 0.1rem !important;
}

.Organisation .ui.steps .content {
  color: #353f55;
}

.Organisation .ui.steps .step:before {
  color: #a7b3a3 !important;
}

.Organisation .ui.steps .step.active,
.Organisation .ui.steps .step.active:after {
  background-color: #f5f1f1 !important;
}

.Organisation .step-description {
  padding-bottom: 45px;
}

.Organisation h2 {
  color: #003476;
}

.Organisation h2 .fas {
  color: #003476;
}

.Organisation .buttons {
  margin: 0 auto;
  text-align: center;
  margin-top: 70px;
}

.Organisation .buttons button {
  width: 130px;
}

.Organisation .buttons button:nth-child(1) {
  margin-right: 10%;
}

.Organisation .buttons button:nth-child(2) {
  margin-left: 10%;
}

.Organisation .Menu .selection {
  background-image: linear-gradient(
    #fafafa,
    #fafafa 50%,
    hsla(0, 0%, 85.5%, 0.288) 0,
    hsla(0, 0%, 85.5%, 0.288)
  ) !important;
}

.Organisation .Menu .item .fas {
  color: #003476 !important;
}

.Organisation .hiddenInput {
  display: none !important;
}

.Organisation .error-description .back-link {
  font-weight: 800;
  margin-top: 20px;
  display: block;
}

.signature input {
  text-overflow: ellipsis;
}

.popup:before {
  background-color: #003476 !important;
  border-color: #fafafa !important;
}

.popup {
  background-color: #003476 !important;
  border-color: #fafafa !important;
  color: rgb(252, 252, 246) !important;
}

@-webkit-keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  .ui.action.input.signature {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1300px) {
  .Organisation .ui.container {
    width: 700px;
  }
}

@media only screen and (max-width: 1048px) {
  .Organisation .ui.container {
    width: 80vw;
  }
}

@media only screen and (min-width: 480px) and (max-width: 850px) {
  .Organisation.Page {
    background-color: #fff;
  }
  .Organisation .container {
    background-color: #fff;
    margin-top: 160px;
    min-height: 83vh;
    width: 100vw;
    margin-bottom: 80px;
    padding-top: 30px;
    padding-bottom: 80px;
    box-shadow: none;
    opacity: 0;
    -webkit-animation: Show 0.4s;
            animation: Show 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    position: static;
    margin: 100px auto;
  }
}

@media only screen and (max-width: 500px) {
  .Organisation .ui.container {
    margin-top: 90px;
  }
}

@media only screen and (max-width: 480px) {
  .Organisation.Page {
    background-color: #fff;
  }
  .Organisation .container {
    background-color: #fff;
    margin-top: 160px;
    min-height: 83vh;
    width: 100vw;
    margin-bottom: 80px;
    padding-top: 30px;
    padding-bottom: 80px;
    box-shadow: none;
    opacity: 0;
    -webkit-animation: Show 0.4s;
            animation: Show 0.4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    position: static;
    margin: 100px auto;
  }
}

@media only screen and (max-width: 456px) {
  .Organisation .Check .description {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 390px) {
  .Organisation .Check .description {
    padding-left: 0;
    padding-right: 0;
  }
}

.link-menu > a {
  color: rgba(181, 100, 87, 0.76) !important;
}

.link-menu:hover > a {
  /* color: #B56357 !important; */
  transition: all 0.5s;
}

.link-menu {
  display: inline-block;
  position: absolute;
  top: 40px;
  right: 40px;
  color: rgba(181, 100, 87, 0.76) !important;
  font-weight: 800;
  font-size: 27px;
  text-align: center;
}

[data-tooltip]:before {
  width: 0.71428571em !important;
  height: 0.71428571em !important;
  background-color: #353f55 !important;
}

[data-tooltip]:before,
[data-tooltip]:after {
  border-color: #353f55;
  color: white;
}

[data-tooltip]:after {
  background-color: #353f55;
  min-width: 80px;
  min-height: 50px;
  word-wrap: break-word;
}

[data-tooltip]:hover:after,
[data-tooltip].show-popup:after {
  opacity: 1;
  visibility: visible !important;
  -webkit-transform: translateX(-50%) scale(1) !important;
          transform: translateX(-50%) scale(1) !important;
}

[data-tooltip].show-popup:before {
  opacity: 1;
  visibility: visible !important;
  -webkit-transform: rotate(45deg) scale(1) !important;
          transform: rotate(45deg) scale(1) !important;
}

@media only screen and (max-width: 770px) {
  [data-tooltip].show-popup:after,
  [data-tooltip].show-popup:before {
    opacity: 1;
    visibility: visible !important;
  }

  [data-tooltip]:hover:after,
  [data-tooltip].show-popup:after {
    -webkit-transform: translateX(-100%) scale(1) !important;
            transform: translateX(-100%) scale(1) !important;
  }

  [data-tooltip]:hover:before,
  [data-tooltip].show-popup:before {
    -webkit-transform: rotate(25deg) translate(-9px, 2px) scale(1) !important;
            transform: rotate(25deg) translate(-9px, 2px) scale(1) !important;
  }
}

@media only screen and (max-width: 315px) {
  [data-tooltip].show-popup:after,
  [data-tooltip]:hover:after {
    min-width: 0;
    overflow-wrap: break-word !important;
    word-break: break-all !important;
    -webkit-transform: translateX(-75%) !important;
            transform: translateX(-75%) !important;
  }

  [data-tooltip]:hover:before,
  [data-tooltip].show-popup:before {
    -webkit-transform: rotate(45deg) scale(1) !important;
            transform: rotate(45deg) scale(1) !important;
  }
}

.link-menu {
  /* display: none; */
}

.Menu {
  padding-bottom: 30px;
}

.Menu .item .header {
  color: #003476 !important;
}

.Menu,
.Menu .header {
  color: #003476 !important;
}

.Menu .description h3 {
  padding-left: 0;
  color: #353f55;
}

.Menu .selection p {
  color: black;
  opacity: 0.8;
}

.Menu .selection .item {
  margin-top: 0 !important;
  text-align: left;
}

.Menu .selection {
  position: relative !important;
  margin-top: 7% !important;
  border: 1px solid #353f55 !important;
  background-color: transparent;
  background-image: linear-gradient(
    rgb(252, 252, 246),
    rgb(252, 252, 246) 50%,
    rgba(218, 218, 218, 0.288) 50%,
    rgba(218, 218, 218, 0.288)
  ) !important;
  background-size: 100% 200% !important;
  cursor: pointer;
}

.Menu .selection:hover {
  background-position: 0 -100% !important;
  transition: background-position 0.3s;
}

.Menu .selection:hover .header {
  color: #353f55 !important;
}

.Menu .selection:hover .arrow {
  width: 12%;
  transition: all 0.2s;
}

.Menu .selection > div {
  display: block;
}

.Menu .selection:after {
  content: '';
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.Menu .item {
  margin-top: 0;
}

.Menu .item .icon {
  height: 100% !important;
  background-color: transparent;
  display: block;
  width: 10%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 40px;
  margin-left: 5px;
}

.Menu .item .icon .fas {
  position: relative;
  top: calc(50% - 18px);
}

.Menu .item .header {
  font-size: 18px !important;
  color: #003476 !important;
  padding-left: 10px;
}

.Menu .item .content {
  padding: 16px 10px !important;
  margin-left: 8% !important;
  display: inline-block;
  vertical-align: middle;
  width: 90%;
}

.Menu .item .description {
  font-size: 16px !important;
  width: 80%;
  padding-bottom: 10px;
  padding-left: 10px;
  opacity: 0.85;
}

.Menu .item .arrow {
  height: 100%;
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  background-color: transparent;
  width: 10%;
  text-align: center;
  border: none;
  cursor: pointer;
  margin-right: 4px;
}

.Menu .item .arrow .fas {
  position: relative;
  font-size: 40px;
  position: relative;
}

.Menu .item .fas {
  color: #353f55cc !important;
  transition: color 0.3s;
}

.Menu.fade-out {
  opacity: 0;
  transition: opacity 0.4s;
}

@media only screen and (max-width: 1280px) {
  .Menu .items .icon,
  .Menu .item .arrow .fas {
    font-size: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .Menu .items .icon {
    display: inline-block;
    height: auto !important;
    padding-top: 17px;
    font-size: 30px;
    margin-left: 3px;
    margin-top: 22px;
    font-size: 20px;
  }

  .Menu .items .content {
    width: 90%;
    margin: 0 !important;
  }

  .Menu .items .content .header {
    margin-left: 34px;
    padding-left: 5px;
  }

  .Menu .items .content .description {
    padding-right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .Menu .items h4 {
    font-size: 17px !important;
  }

  .Menu .items {
    padding: 0 10px;
  }

  .Menu .items .icon {
    font-size: 20px;
    margin-left: 3px;
    margin-top: 22px;
  }

  .Menu .items .content {
    width: 85%;
    margin: 0 !important;
  }

  .Menu .item .description {
    width: 90%;
  }

  .Menu .items .content .header {
    margin-left: 28px;
    font-size: 16px !important;
  }

  .Menu .items .description {
    padding-right: 5px;
  }

  .Menu .items .arrow {
    width: 16%;
    margin-right: 0;
  }
  .Menu .selection:hover .arrow {
    width: 15%;
    transition: all 0.2s;
  }

  .Menu .items .arrow .fas {
    font-size: 27px;
  }
}

@media only screen and (max-width: 320px) {
  .Menu .items .content .header {
    margin-left: 18px;
  }
}

.Organisation .TokenEntry .Token {
  padding-top: 10px;
  margin-bottom: 20px;
}

.Organisation .TokenEntry .field {
  display: inline-flex;
  width: 90%;
}
.Organisation .TokenEntry .label {
  margin-bottom: 0;
}
.Form.Token input[type='text'] {
  padding: 0.6em 0.7em;
}

.Form.Token .input-group {
  margin-bottom: 0;
}

.Organisation .TokenEntry .hint {
  font-size: 13px;
  color: rgb(127, 125, 125);
}

.Organisation .TokenEntry .search-button {
  margin-top: 15px;
}

.Organisation .TokenEntry .description > h3 {
  color: #353f55;
}

.Organisation .modal .content {
  padding: 21px 30px !important;
  height: 80px;
}

.Organisation .TokenEntry .field .label {
  font-weight: normal;
}

.search-button {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

#downloadPdf {
  display: inline;
}

#downloadPdf input {
  display: none !important;
}

#downloadPrintable {
  display: inline;
}

#downloadPrintable input {
  display: none !important;
}

.modal-wrapper {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 3;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.modal {
  width: 70%;
  min-width: 500px;
  background-color: rgba(255, 255, 255, 1);
  overflow-y: auto;
  height: 85vh;
  display: block;
  margin: 0 auto;
  z-index: 3;
  position: fixed;
  left: 15%;
  top: 5vh;
}

.modal .content {
  min-height: 50vh;
  padding: 10px 40px;
  margin-top: 80px;
}

.modal .header {
  font-size: 20px;
  background-color: #003476;
  color: rgb(255, 255, 255);
  padding-left: 40px;
  position: relative;
  position: fixed !important;
  width: 70%;
  box-sizing: border-box;
  z-index: 100;
}

.modal .header h4 {
  display: inline-block;
}

.modal .header button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 20px;
  border: 0;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 32%;
}

.modal .content table {
  border-collapse: collapse;
}

.modal .content table td {
  padding: 15px 20px;
  font-size: 16px;
}

.modal .content .side_description {
  font-size: 40px;
}

.modal #copiedNotification,
.pill-content td #copiedNotification {
  color: rgba(0, 128, 0, 0.76);
  font-size: 15px;
  text-align: left;
  height: 10px;
  opacity: 0;
  margin-top: 5px;
  padding-bottom: 10px;
  font-size: 0.7em;
}

.modal #copiedNotification.show,
.pill-content td #copiedNotification.show {
  -webkit-animation: Show 0.3s;
          animation: Show 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.modal #copiedNotification.disappeared,
.pill-content td #copiedNotification.disappeared {
  opacity: 1;
  -webkit-animation: Disappear 1.8s;
          animation: Disappear 1.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.modal .actions .left,
.modal .actions .right {
  margin-bottom: 13px;
}

.modal .actions,
.pill-content .actions {
  display: block;
  text-align: center;
}
.pill-content .actions {
  padding: 3%;
}

.pill-content .actions button {
  font-size: 14px;
  margin: 10px 10px;
  padding: 0 16px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.pill-content #downloadPdf button,
.pill-content #downloadPrintable button {
  padding: 10px 35px;
}

.pill-content {
  width: 100%;
}

.modal .actions .left button {
  margin-left: 2px;
  margin-right: 2px;
}

.modal .actions .right {
  float: right;
}

/* .pill-content tr:nth-last-child(1) {
  border-bottom: none;
} */

@media only screen and (max-width: 768px) {
  .modal .content .side_description {
    font-size: 18px !important;
    padding-right: 0px !important;
  }

  .modal .header {
    position: fixed;
    width: 100%;
    padding-left: 10px;
  }

  .modal .header button {
    right: 5%;
  }

  .modal .content {
    padding: 80px 5px;
    margin-top: 0;
  }

  .modal #closeModal {
    display: none;
  }

  .modal .actions button {
    margin-right: 3px;
    margin-left: 3px;
  }

  .modal .actions .left {
    float: none;
  }

  .modal {
    width: 100%;
    min-width: 250px;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
  }

  .modal .content .inner-td {
    width: 100%;
  }
}

@media only screen and (max-width: 386px) {
  .modal .content {
    padding: 40px 5px;
    margin-top: 40px;
  }

  .modal .content table td {
    padding: 0 3px;
  }
}

.Tooltip {
  position: relative;
}

.Tooltip:before,
.Tooltip:after {
  z-index: 100;
  position: absolute;
  left: 0;
  bottom: 125%;
}

.Tooltip:hover:before {
  background-color: rgb(150, 150, 150);
  margin-left: 3px;
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  -webkit-transform: translateY(5px) rotate(45deg);
          transform: translateY(5px) rotate(45deg);
}

.Tooltip:hover:after {
  background-color: rgb(150, 150, 150);
  color: white;
  padding: 7px 10px;
  content: attr(data-tip);
  -webkit-transform: translateY(0);
          transform: translateY(0);
  width: 120px;
  font-size: 13px;
}

.Form input[type='text'],
.Form select {
  border-radius: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 18px;
  padding: 0.6em 1em;
  outline: 0;
  transition: color 0.1s ease, border-color 0.1s ease;
  width: 100%;
}

.Form input[type='text'].labeled,
.Form select.labeled {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Form input[type='text'].date {
  text-align: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Form input[type='text'].date:not(:last-child) {
  border-radius: 0;
}

.Form input:focus {
  border-color: #85b7d9;
}

.Form label:not(.checkbox-label) {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: rgb(236, 236, 236);
  padding: calc(0.6em + 3px) 1em;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-size: 16px;
  min-width: 90px;
  text-align: center;
}

.Form .checkbox-label {
  display: block;
}

.Form label.small {
  min-width: 10px;
  border-radius: 0;
}

.Form .input-group {
  display: flex;
  align-items: stretch;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Form button {
  border-radius: 5px;
  padding: 0.46647em 1.2828em;
  color: white;
  cursor: pointer;
  border: 0;
  font-size: 1.1rem;
}

.Form button.primary {
  background-color: #00703c;
}

.Form button.primary:hover {
  background-color: #004e2a;
}

.Form select option.placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.Form .tooltip-label {
  border-bottom: 1px dotted black;
  cursor: help;
}

@media only screen and (max-width: 1200px) {
  .Form input[type='text'] {
    padding: 0.6em 0.3em;
  }

  .Form label:not(.checkbox-label) {
    padding: calc(0.6em + 3px) 0.1em;
  }

  /* .Form label.small {
    
  } */

  .Form input[type='text'].date {
    padding: 0.6em 0.1em;
  }
}

@media only screen and (max-width: 850px) {
  .Form label:not(.checkbox-label) {
    padding: calc(0.6em + 3px) 1em;
  }
}

@media only screen and (max-width: 580px) {
  .Form label:not(.checkbox-label) {
    padding: calc(0.6em + 3px) 0.1em;
  }
}

/* @media only screen and (max-width: 456px) {
  .Form label:not(.checkbox-label) {
    min-width: 88px;
  }
} */


@media only screen and (max-width: 300px) {
  .Form label:not(.checkbox-label) {
    padding: calc(0.6em + 3px) 1px;
  }
}
.pill {
  font-family: 'Roboto Slab';
  display: block;
  margin-bottom: 20px;
  width: 90%;
  min-width: 350px;
  display: block;
  border-left: 4px solid rgb(170, 170, 170);
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.pill .pair .mini-header {
  font-weight: bold;
  color: gray !important;
  margin-right: 10px;
}
.pill .pill-header {
  background-color: white;
  position: relative;
  cursor: pointer;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 18px;
  box-sizing: border-box;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    border-left 0.1s ease-out;
  padding-right: 50px;
}

.pill .pill-header .pill-header-text {
  font-size: 16px;
}

.pill .pill-header .pill-header-tag {
  font-size: 16px;
  word-wrap: break-word;
  font-weight: 800;
}

.pill .pill-header:hover,
.pill.opened .pill-header {
  background: rgba(252, 252, 252);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    border-left 0.4s ease-out;
}

.pill.opened .pill-header {
  background: rgba(252, 252, 252);
  border-bottom-left-radius: 0;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    border-left 0.3s ease-out;
}

.pill:not(.closed) .pill-header {
  background: rgba(248, 248, 248);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.pill .pill-header-btn {
  all: unset;
  position: absolute;
  right: 20px;
  top: 15px;
  bottom: 0;
  font-size: 20px;
  height: 30px;
  margin: auto;
}

.pill .pill-header-btn .fas {
  font-size: medium;
}

.pill.closed .pill-content {
  display: none;
}

.pill .pill-content {
  background-color: white;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 120px;
  box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.1);
}

.pill .action-area {
  text-align: right;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pill table {
  font-size: 17px;
  width: 100%;
  border-collapse: collapse;
}

.pill tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.pill td:nth-child(odd) {
  font-weight: bold;
  padding: 15px 5px;
  padding-left: 20px;
}

.pill td:nth-child(even) {
  padding: 15px 20px 15px 15px;
  word-break: break-word;
}

.pill .pair .mini-header {
  display: inline-block;
}
.pill table.scopes td {
  font-weight: 400;
}

.pill table.scopes svg {
  margin-right: 8px;
}

@media screen and (max-width: 850px) {
  .pill {
    min-width: 0 !important;
    width: 100% !important;
  }

  .pill-area {
    /* width: 95% !important; */
    margin-left: 0 !important;
  }

  .pill-area * {
    transition: none !important;
  }

  .pill.closed .pill-header:hover {
    background-color: white !important;
  }

  /*.pill.colored.closed .pill-header:hover {
        background: rgba(220, 200, 200) !important;
        border-left: 20px solid transparent !important;
    }*/
}

@media screen and (max-width: 520px) {
  .pill .pill-header {
    padding-left: 10px;
  }

  .pill .pill-header .pill-header-text {
    font-size: 15px;
  }

  .pill table {
    font-size: 14px;
    table-layout: fixed;
  }

  .pill td:nth-child(odd) {
    padding-left: 10px;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .pill td:nth-child(even) {
    text-align: right;
    padding-right: 5px;
    /* word-wrap: break-word;
        white-space: normal; */
    /* white-space:pre-wrap;
        word-break:break-word; */
  }

  .pill table.scopes td {
    padding-left: 0;
  }

  .pill .pair .mini-header {
    display: block;
  }
  .row .value {
    margin-top: 10px;
  }

  .pill td:nth-child(odd) {
    width: 36%;
  }
  .pill td:nth-child(even) {
    text-align: left;
  }

  .pill .pair .mini-header {
    display: block;
  }
}

@media screen and (max-width: 320px) {
  .pill table {
    font-size: 13px;
  }
}

.Logout .ui.info.message {
  margin-top: 30px;
}

.Logout .ui.info.message ul {
  padding-left: 10px;
}

.Logout .ui.info.message ul > li {
  margin-bottom: 15px;
}

.Logout a {
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  transition: color 0.5s, border 0.5s;
}

.Logout a:hover {
  color: #00aeef !important;
  border-bottom: 1px solid #00aeef !important;
  transition: color 0.5s, border 0.5s;
}

.Logout a:hover .fas {
  color: #00aeef !important;
}

.Logout .hidden {
  display: none;
}

.Logout .back-link {
  position: relative;
  top: 12px;
}

.GeneralError {
  position: static;
  overflow: auto;
}

.GeneralError .Header {
  position: absolute;
}

.GeneralError .error-body {
  padding-top: 7vh;
  min-height: 100vh;
}

.GeneralError .error-inner {
  width: 80%;
  margin: 0 auto;
  margin-top: 20vh;
}

.GeneralError .error-message {
  font-size: 40px;
  font-weight: 700;
  width: 40%;
  display: inline-block;
  line-height: 55px;
  float: left;
}

.GeneralError .error-header {
  font-size: 70px;
  margin-bottom: 30px;
}

.GeneralError a {
  font-size: 20px;
}

.GeneralError .error-icon {
  width: 60%;
  display: inline-block;
  position: relative;
}

.GeneralError .error-icon svg {
  width: 80%;
  margin-left: 10%;
  margin-top: -30px;
}

.GeneralError #tryLater {
  font-size: 30px;
  margin-top: 20px;
}

.Verifier > .input_area {
  margin-bottom: 30px;
}

.Verifier .input_area textarea {
  width: 100%;
  min-height: 150px;
}

.Verifier > .action_area {
  text-align: center;
}

.Verifier > .action_area > button {
  width: 60%;
  min-width: 172px;
  margin: 0 auto;
}

.Verifier > .result_area {
  margin-top: 30px;
}

.Verifier > .result_area > .data_area {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  padding: 0 40px;
}

.Verify > .description > h3 {
  color: #353f55;
}

.circle-loader {
  --success-color: rgba(0, 130, 90, 1);
  --fail-color: rgba(220, 0, 20, 0.8);
  /* --no-match-color: rgba(0, 0, 0, 0.3); */
  --no-match-color: #ff7900;
  margin: 0 auto;
  border: 3px solid rgba(0, 0, 0, 0.3);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-left-color: rgba(0, 0, 0, 0.7);
  -webkit-animation-name: loader-animation;
          animation-name: loader-animation;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  box-sizing: border-box;
  text-align: center;
  vertical-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mark {
  -webkit-animation-name: opacity-animation;
          animation-name: opacity-animation;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  font-size: 60px;
}

.checkmark {
  color: var(--success-color) !important;
}

.failmark {
  color: var(--fail-color) !important;
}

.no-match-mark {
  color: var(--no-match-color) !important;
}

.finished {
  -webkit-animation: none;
          animation: none;
  border-left-color: rgba(0, 0, 0, 0.3);
}

.success {
  border: 3px solid var(--success-color);
}

.fail {
  border: 3px solid var(--fail-color);
}

.no-match {
  border: 3px solid var(--no-match-color);
}

@-webkit-keyframes loader-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loader-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes opacity-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 530px) {
  .Verifier > .result_area > .data_area {
    padding: 0 0;
  }
}

.CompactDiploma table {
  width: 100%;
  table-layout: fixed;
}

.CompactDiploma .side_description {
  width: 20px;
}

.CompactDiploma table.inner-table strong {
  color: #002452;
}

.CompactDiploma table.inner-table {
  border: 1px solid rgb(150, 150, 150);
  border-collapse: initial;
  border-spacing: 0;
}

.CompactDiploma table.inner-table tr td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
}

.CompactDiploma table.inner-table tr:not(:first-child) td {
  /* border-top: 1px solid rgb(150, 150, 150); */
}

.CompactDiploma table.inner-table td:nth-child(even) {
  width: 65%;
}

.CompactDiploma #copiedNotification {
  color: rgba(0, 128, 0, 0.76);
  font-size: 15px;
  text-align: left;
  height: 10px;
  opacity: 0;
  margin-top: 5px;
  padding-bottom: 10px;
}

.CompactDiploma #copiedNotification.show {
  -webkit-animation: Show 0.3s;
          animation: Show 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.CompactDiploma #copiedNotification.disappeared {
  opacity: 1;
  -webkit-animation: Disappear 1.8s;
          animation: Disappear 1.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.CompactDiploma #textSignatureRow td {
  padding-top: 7px;
  padding-bottom: 20px;
}

.Check > .description > h3 {
  color: #353f55;
}

.collapse-steps {
  margin-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.collapse-steps > .step {
  border: 1px solid rgba(120, 120, 120, 0.5);
  padding-left: 15px;
  padding-right: 15px;
}

.collapse-steps > .step > .header {
  font-size: 18px;
  padding-top: 10px;
  position: relative;
}

.collapse-steps > .step:not(.opened) > .header {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 6px;
}

.collapse-steps > .step > .header > div > button {
  background: 0;
  padding-left: 0;
  padding-right: 0;
  float: right;
  position: absolute;
  top: 5px;
  border: 0;
  right: 0;
}

.collapse-steps > .step > .header > div > button:hover {
  color: #003476;
  transition: color 0.5s;
}
.collapse-steps > .step > .header > .icon {
  font-size: 16px;
  padding-bottom: 2px;
}

.collapse-steps > .step.opened > .header {
  font-weight: 800;
}

.step-content {
  height: 0;
  overflow: hidden;
  transition: padding 0.3s;
}

.step.opened > .step-content {
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.step.completed .before-completion,
.step.uncompleted .after-completion {
  display: none;
}

.step.completed .after-completion,
.step.uncompleted .before-completion {
  display: initial;
}

.step.completed {
  opacity: 0.75;
  background-color: #f4f4f4;
}

.step.closed > .header > span {
  opacity: 0.7;
}

.step-content > .form {
  max-width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.step-content > .form > .field > .input > input {
  font-size: 18px;
}

.step-content > .form > .field > .ssn > input {
  padding-left: 20px;
}

.dropdown-wrapper {
  display: inline-flex;
}

.dropdown-wrapper > .label {
  margin-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 15px;
}

.step-content > .data_area {
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
}

.step-content > .data_area > .button-area {
  margin-bottom: 10px;
  margin-left: 23px;
  position: relative;
}

.step-content > .data_area > .button-area .ui.button {
  width: calc(50% - 5.5px);
  margin-right: 2px;
  margin-left: 2px;
}

.step-content > .data_area > .button-area .ui.button:nth-of-type(2) {
  margin-right: 0;
}

.step-content .permission-box {
  margin-top: 50px;
  text-align: left;
  display: flex;
  margin-bottom: 24px;
}

.step-content .permission-box input {
  align-self: flex-start;
  margin-right: 10px;
}

.step-content .error {
  color: rgb(116, 44, 60);
  font-weight: bold;
  top: 10px;
  position: relative;
  display: inline;
}

.step-content .error.hidden {
  display: none;
}

#date-wrapper .error {
  opacity: 1;
  transition: all 0.4s;
}

#date-wrapper .error.hidden {
  display: block;
  opacity: 0;
  transition: all 0.8s;
}

#date-wrapper {
  margin-bottom: 0.8em;
}

.label abbr {
  display: inline;
}

.label .fas {
  font-size: 14px;
  display: inline;
  font-weight: normal;
  position: absolute;
  left: 1px;
}

table.inner_table td:nth-child(even) {
  width: 65%;
}

.step-content .signature button {
  width: 40%;
}

.ui.action.input:not([class*='left action']) > input.signature-input {
  width: 60%;
}

.less-visible {
  opacity: 0.8;
}

#downloadPdf {
  display: inline;
}

#downloadPdf button,
#downloadPrintable button {
  padding: 10px 0;
}

#downloadPdf input {
  display: none !important;
}

#downloadPrintable {
  display: inline;
}

#downloadPrintable input {
  display: none !important;
}

.light-success {
  font-size: 15px;
  font-weight: 400;
  margin: 10px 0 0 18px;
}

.light-failure {
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
}
@media only screen and (max-width: 1100px) {
  .collapse-steps > .step > .header > div > button {
    float: none;
    position: static;
    margin-top: 10px;
    margin-left: calc(100% - 70px);
  }
}

@media only screen and (max-width: 700px) {
  .step-content > .form {
    box-sizing: border-box;
    width: 100%;
  }

  .step-content .form .ui.input {
    display: inline;
  }

  .step-content .form .ui.input input {
    display: block;
    width: 100% !important;
    border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
    border-bottom-left-radius: 0.28571429rem !important;
    font-size: 15px;
  }

  .step-content .form .ui.input input {
    display: block;
    width: 100% !important;
    border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
    border-bottom-left-radius: 0.28571429rem !important;
    font-size: 15px;
  }

  .step-content .form .ui.input input:focus {
    border-left-color: #85b7d9 !important;
  }

  .step-content .field {
    line-height: 0.8em;
    width: 100%;
  }

  .step-content .form .ui.label {
    border-top-right-radius: 0.28571429rem !important;
    border-bottom-left-radius: 0 !important;
    display: inline-block;
    width: auto;
    font-size: 16px;
  }

  .step-content .form .ui.buttons {
    display: block;
    width: 100% !important;
  }

  .step-content .form .ui.buttons button {
    display: block !important;
    width: 100% !important;
  }

  .step-content .form .ui.buttons button:first-child {
    border-top-right-radius: 0.28571429rem !important;
  }

  .step-content .form .ui.buttons button:last-child {
    border-bottom-left-radius: 0.28571429rem !important;
    border-top-right-radius: 0 !important;
  }

  .step-content .field.level-pick {
    display: inline-block;
  }

  .step-content .field.level-pick {
    position: relative !important;
    width: 100% !important;
  }

  .step-content .date-field {
    display: block;
  }

  .step-content .date-field .input-area .label {
    width: 32px;
    display: inline-block;
    font-size: 16px;
  }

  .step-content .input-area {
    display: inline-flex;
  }

  input.date {
    text-align: center;
    height: 100%;
    width: calc((100% - 66px) / 2) !important;
  }

  input.date.small {
    text-align: center;
    width: calc((100% - 66px) / 4) !important;
  }

  .step-content .dropdown-wrapper {
    display: block;
  }

  .step-content .dropdown.search.selection {
    width: 100%;
    min-width: 100%;
  }

  .step-content .date-field .input-area .input {
    border-bottom-left-radius: 0.28571429rem !important;
  }

  .step-content .selection {
    border-bottom-left-radius: 0.28571429rem !important;
  }

  .collapse-steps > .step > .header > div > .button {
    float: none;
    position: static;
    margin-left: calc(100% - 80px);
  }

  .collapse-steps .open {
    font-size: 17px;
  }
}

@media only screen and (max-width: 600px) {
  .step-content .data_area {
    position: relative;
  }

  .step-content .button-area {
    display: inline-flex;
    width: 90%;
  }

  .step-content .button-area form {
    width: calc(50% - 3.5px);
  }

  .step-content .button-area form button {
    width: 96% !important;
  }

  .step-content .data_area button {
    height: 70px !important;
  }

  .step-content .signature {
    height: 50px;
    width: 100%;
  }

  .step-content .signature .not-mobile {
    display: none;
  }

  .step-content .signature .fas {
    display: inline-block;
  }

  .step-content #textSignatureRow {
    height: 134px;
  }

  .step-content .signature button {
    width: 20%;
    height: 100% !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    text-align: center !important;
  }

  .ui.action.input:not([class*='left action']) > input.signature-input {
    width: 73%;
    border-right-color: rgba(34, 36, 38, 0.15) !important;
  }
}

@media only screen and (max-width: 456px) {
  .collapse-steps {
    padding-left: 15px;
    padding-right: 15px;
  }

  #not-authorized {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 80px;
  }

  #not-authorized.hidden {
    display: none;
  }

  #check-step2 {
    padding-left: 0;
    padding-right: 0;
  }

  #check-step2 .header {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .step-content .data_area {
    width: 100%;
  }
}

@media only screen and (max-width: 390px) {
  .collapse-steps {
    padding-left: 0;
    padding-right: 0;
  }

  .collapse-steps > .step,
  #check-step2 .header {
    padding-left: 5px;
    padding-right: 5px;
  }

  .step-content > .form {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 330px) {
  .not-mobile {
    display: none;
  }

  .step-content .data_area .button-area button .fas {
    font-size: 23px;
  }

  .step-content #textSignatureRow {
    height: 150px;
  }

  .Page .content.organisationContent {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.Collapse .body:not(.open) {
  display: none;
}

hr.solid {
  opacity: 0.3;
  margin-left: 0%;
  margin-bottom: 0px;
}

.boxed {
  background-color: rgba(243, 243, 243, 0.966);
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Collapse .section {
  margin-bottom: 27px;
}

.inv-boxed {
  border: 0px;
  text-align: left;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.inv-boxed.bordered {
  border-left: 3px solid #003476;
}

.comment {
  border: 0px;
  text-align: left;
  display: flex;
  color: rgb(60, 60, 60);
  font-size: 15px;
}

.inv-boxed:hover {
  cursor: pointer;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid;
  border-bottom: 0px;
  opacity: 0.4;
  margin-top: -1px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 0px;
  border-bottom: 12px solid;
  opacity: 1;
  margin-top: -13px;
  color: #003476;
}

.stat {
  width: 120px;
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 5px;
}

.institutionLogoContainer {
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  height: 32px;
  width: 32px;
  margin-right: 10px;
  margin-top: -10px;
}

@media only screen and (max-width: 470px) {
  .inv-boxed {
    font-size: 15px;
  }
}

.AdoptionRates {
  min-height: 100vh;
  background-color: #fff;
}

.AdoptionRates .mobile {
  display: none;
}

.AdoptionRates > * {
  -webkit-animation: Show 1s;
          animation: Show 1s;
}

.AdoptionRates > .content {
  width: 90%;
  min-height: 90vh;
  padding-top: 200px;
  margin: 0 auto;
  padding-bottom: 50px;
  position: relative;
}

.AdoptionRates .tiny-content {
  text-align: center;
}

.AdoptionRates > .content > .header {
  color: #003476;
  font-size: 2.5em;
  margin-bottom: 60px;
}

.AdoptionRates table {
  width: 100%;
  margin: 0 auto;
  background-color: transparent !important;
  border: none !important;
}

.AdoptionRates table.outer {
  margin-bottom: 70px;
  display: block;
  width: 100%;
  overflow: auto;
  border-collapse: collapse;
}

.AdoptionRates table.outer tbody tr {
  border-bottom: 1px solid rgb(190, 190, 190);
}

.AdoptionRates table th {
  color: #353f55 !important;
  background-color: #f9f9f9 !important;
  padding: 15px 20px;
}

.AdoptionRates table td {
  padding-left: 20px;
  padding-right: 20px;
}

.AdoptionRates tr.header {
  font-weight: 800;
  color: #353f55 !important;
  background-color: transparent;
}

.spinner {
  text-align: center;
  width: 100%;
}

.AdoptionRates .institutionLogoContainer {
  height: 46px;
  width: 46px;
  min-width: 46px;
  text-align: center;
}

.institutionLogo {
  height: 100%;
  width: auto;
  position: relative;
}

.AdoptionRates div.comments {
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 300px !important;
  min-width: 120px !important;
  overflow: auto;
}

.AdoptionRates div.comments .content {
  opacity: 0.8;
}

.AdoptionRates .centered.grid {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.AdoptionRates .pagination .active.item {
  color: #353f55 !important;
  border-color: #353f55 !important;
}

.AdoptionRates table:not(.inner) td:nth-child(1) {
  text-align: center;
  padding-right: 0;
  width: 30px;
}

.AdoptionRates table.inner td {
  text-align: left !important;
  padding: 10px 10px;
}

.AdoptionRates tr table.inner tr {
  box-shadow: inset 0 -1px 0 0 rgba(163, 163, 163, 0.267) !important;
}
.AdoptionRates tr table.inner tr:last-of-type {
  box-shadow: none !important;
}

.AdoptionRates .mobileHeader {
  font-weight: bold;
  display: none;
  margin-right: 15px;
  color: #353f55f0;
}

.AdoptionRates #beta-adopters {
  display: block;
  width: 99%;
  text-align: right;
}

@-webkit-keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1470px) {
  .AdoptionRates > .content {
    width: 100%;
  }
}

@media only screen and (max-width: 1310px) {
  .AdoptionRates table td {
    padding-left: 2px;
    padding-right: 2px;
  }

  .AdoptionRates table th {
    padding-left: 5px;
    padding-right: 5px;
  }

  .AdoptionRates table td {
    font-size: 14px;
  }

  .AdoptionRates .strong {
    font-size: 14px;
  }
}

@media only screen and (max-width: 999px) {
  .AdoptionRates > .content {
    width: 90%;
  }

  .AdoptionRates table.outer {
    display: none;
  }

  .AdoptionRates .mobile {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .AdoptionRates .content {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 762px) {
  .AdoptionRates > .content > .header {
    color: #003476;
    font-size: 1.5em;
    margin-bottom: 40px;
    margin-top: 15%;
  }
}

.NewsPage {
  min-height: 100vh;
  background-color: #fff;
  font-size: 18px;
}
.NewsPage .breadcrumbs {
  position: relative;
  color: #003476;
  font-size: 17px;
}

.NewsPage .breadcrumbs .paths {
  margin-left: 1%;
}
.NewsPage .breadcrumbs .home {
  margin-right: 4px;
}

.NewsPage > .content {
  width: 80%;
  min-height: 90vh;
  padding: 150px 0;
  margin: 0 auto;
  position: relative;
}

.NewsPage > .content .header:first-child {
  color: #003476;
  display: block;
  text-align: left;
  font-size: 2.3em;
  margin: 30px 0 30px 0;
}

.NewsPage > .content .header {
  color: #003476;
  display: block;
  text-align: left;
  font-size: 2em;
  margin: 45px 0 30px 0;
}

.TermsOfUse {
  min-height: 100vh;
  background-color: #fff;
}

.TermsOfUse > * {
  -webkit-animation: Show 1s;
          animation: Show 1s;
}

.TermsOfUse > .content {
  width: 70%;
  min-height: 90vh;
  padding: 150px 0;
  margin: 0 auto;
  position: relative;
}

.TermsOfUse > .content .header {
  color: #003476;
  font-size: 2.7em;
  margin-bottom: 40px;
}

.TermsOfUse ol#termsOfUseList li {
  counter-increment: list;
  list-style-type: none;
  position: relative;
  font-size: 17px;
  margin: 40px auto;
}

.TermsOfUse ol#termsOfUseList li p {
  text-indent: 0px;
  margin-left: 10px;
}

.TermsOfUse ol#termsOfUseList li:before {
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  background-color: #003476;
  color: rgb(252, 252, 246);
  border-radius: 50%;
  height: 26px;
  width: 26px;
  padding-top: 3.5px;
  padding-left: 9px;
  content: counter(list);
  left: -32px;
  position: absolute;
}

@-webkit-keyframes Show {
  0% {
    opacity: 0;
  }
  1000% {
    opacity: 1;
  }
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  1000% {
    opacity: 1;
  }
}

@media only screen and (max-width: 762px) {
  .TermsOfUse > .content {
    width: 90%;
    min-height: 90vh;
    padding: 100px 0;
    margin: 0 auto;
    position: relative;
    color: #353f55;
  }

  .TermsOfUse > .content .header {
    color: #a7b3a5;
    font-size: 2em;
    margin-bottom: 40px;
    margin-top: 50px;
  }
}

html {
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
body::-webkit-scrollbar {
  width: 0 !important;
}

#developer-header {
  font-size: 30px;
}

.Documentation .header {
  margin-top: 60px;
}

.Documentation .cards > .card .fa {
  color: #00aeef;
  font-size: 80px;
}

.Documentation .cards > .card:hover {
  border: 1px solid #003476 !important;
}

.Documentation .nav .header {
  margin-top: 20px;
  color: #000 !important;
}

.Documentation .language-switch {
  right: 10px;
}

.Documentation #img-govgr {
  right: 10px;
}

.Documentation {
  background-color: #fff;
  display: grid;
  grid-template-areas: 'content nav';
  grid-template-columns: 75% 25%;
}

.Documentation > * {
  -webkit-animation: Show 1s;
          animation: Show 1s;
}

.Documentation .hidden {
  display: none;
}

.Documentation .block {
  display: block;
}

.Documentation .content {
  font-size: 17px;
  grid-area: content;
}

.Documentation > .content {
  box-sizing: content-box;
  overflow-x: hidden;
  width: 100%;
}

.Documentation .main {
  padding: 150px 0;
  width: 85%;
  margin: 0 auto;
  margin-top: 60px;
}

.Documentation .language-switch {
  right: 20px;
}

.Documentation .main a {
  font-weight: bold;
  text-decoration: underline;
}

.Documentation .nav {
  grid-area: nav;
  padding-right: 30px;
  background-color: #f2f2f2;
  border-left: 1px solid #d8d8d8;
  z-index: 1;
}

.Documentation .nav button {
  background: transparent;
  border: none;
  color: #686868;
  font-weight: bold;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
}

.Documentation .nav button .fas {
  margin-left: 7px;
  font-size: 15px;
}

.Documentation .nav .nav-list {
  margin-top: 50px;
  padding-bottom: 20px;
}

.Documentation .nav .nav-list > li {
  padding-bottom: 20px;
}

.Documentation .nav .category {
  margin-bottom: 10px;
}

.Documentation .nav .category.collapsed {
  height: 50px;
}

.Documentation .nav .category .contents {
  max-height: 1000px;
  display: inline-block;
  opacity: 1;
}

.Documentation .nav .category.collapsed .contents {
  max-height: 0;
  display: none;
  pointer-events: none;
  opacity: 0;
}

.Documentation .nav .category.collapsed button .fa-chevron-up {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
}

.Documentation .category .fa-link {
  color: #353f55;
  padding-left: 10px;
}

.Documentation .nav .nav-content-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.Documentation .nav .nav-content {
  height: 100vh;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-top: 150px;
  overflow-y: scroll;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.Documentation .nav .nav-content::-webkit-scrollbar {
  width: 0 !important;
}

.Documentation .nav ul {
  list-style: none;
  padding-left: 0;
}

.Documentation .nav li a {
  /*border-bottom: 2px solid #353f55;*/
  padding-bottom: 4px;
  font-weight: 800;
  line-height: 2.1em;
  width: 100%;
}

.Documentation .contents > li a h4 {
  display: inline;
  width: 100%;
  border-bottom: 2px solid #353f55;
  padding-bottom: 4px;
}

.Documentation .contents > li a.active h4,
.Documentation .contents > li a:hover h4 {
  border-bottom: 2px solid #00aeef;
}

.Documentation .section > li a {
  border: none;
}

.Documentation .nav .subsection a {
  display: block;
}

.Documentation .nav li a:hover {
  /* border-color: #B56357; */
  transition: all 0.2;
}

.Documentation .nav a {
  transition: all 0.4s;
}

.Documentation .nav a.active {
  color: #00aeef;
  /* border-color: #B56357; */
}

.Documentation .nav li.inner a {
  border: none;
  font-weight: normal;
  line-height: 1.4285em;
}

.Documentation .nav .li {
  margin-bottom: 8px;
}

.Documentation .nav .section {
  margin: 7px 0;
  margin-left: 20px;
}

.Documentation .nav .section > li {
  margin-top: 20px;
}

.Documentation .nav .subsection {
  margin: 15px 0;
  margin-left: 15px;
  list-style-type: disc;
}

.Documentation .nav h6 {
  margin: 0;
}

.Documentation p,
.Documentation li {
  color: #3a3a3a;
}

.Documentation ul {
  margin: 30px 0;
}

.Documentation li {
  margin-bottom: 14px;
}

.Documentation h1.header {
  color: #353f55;
  font-size: 2em;
  margin-bottom: 40px;
  grid-area: header;
}

.Documentation h2.header {
  color: #000;
}

.Documentation .header-wrapper .header,
.Documentation .header-wrapper .subheader {
  display: inline-block;
}

.Documentation [data-tooltip]:before {
  display: none;
}

.Documentation [data-tooltip]:after {
  opacity: 0.8;
}

.Documentation .header-wrapper a {
  margin-left: 8px;
  opacity: 0.5;
}

.Documentation .header-wrapper a:hover {
  opacity: 1;
}

.Documentation .content .section {
  margin-bottom: 55px;
}

.Documentation .content .section.inner .section.inner {
  margin-bottom: 40px;
}

.Documentation .content .section.inner {
  margin-bottom: 50px;
}

.Documentation h3.subheader {
  color: #000;
  font-size: 1.4em;
  margin-bottom: 22px;
}

.Documentation h4.subheader {
  color: #727272;
  font-size: 1.3em;
  border-bottom: 2px solid #727272;
  padding-bottom: 5px;
  display: inline-block;
}

.Documentation h5.subheader {
  font-size: 1.14rem;
}

.Documentation .example {
  background-color: #f1f1f1;
  border: 1px solid #e5e5e5;
  padding: 20px 15px;
  margin: 30px 0;
  color: rgba(64, 64, 64, 0.9);
  font-family: 'Share Tech Mono', monospace;
}

.Documentation .example {
  word-break: break-all;
}

.Documentation .example span {
  margin: 0;
}

.Documentation .example.code {
  font-size: 16px;
}

.Documentation .example.tree {
  padding: 20px 30px;
}

.Documentation .example.tree li {
  margin-left: 20px;
}

.Documentation .indented {
  margin-left: 2% !important;
  display: block !important;
}

.Documentation .indented p {
  margin-bottom: 0.5em;
}

.Documentation .code .word:nth-child(odd) {
  color: #405da6;
}

.Documentation .code .word:nth-child(even) {
  color: #cf1d41;
}

.Documentation .highlight {
  background: #f4e6eb;
  color: #ce4072;
  font-style: normal;
  font-family: 'Share Tech Mono', monospace;
}

.Documentation .example br {
  margin-bottom: 12px;
}

.Documentation .tag:before {
  content: '<';
}

.Documentation .tag:after {
  content: '>';
}

.Documentation .cursor:before {
  content: '|';
}

.Documentation .nav-toggle {
  display: none;
}

.Documentation .back-to-top {
  text-align: center;
}

.Documentation .back-to-top a {
  font-size: 19px;
}

.Documentation .cards {
  margin-top: 60px;
  margin-bottom: 60px;
}

.Documentation .cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
}

.Documentation .cards > .card {
  border: 2px solid #cfcfcf;
  border-radius: 15px;
  padding: 4rem;
  padding-bottom: 1rem;
  padding-top: 2rem;
  text-align: center;
  font-size: 15px;
}

.Documentation .cards > .card > .content > .description {
  text-align: center;
  margin-top: 1rem;
  color: #3a3a3a;
}

.Documentation .cards > .card:hover {
  border: 1px solid #353f55 !important;
  transition: border 0.5s !important;
  cursor: pointer !important;
}

.Documentation .cards > .card > .content > .header > a {
  text-decoration: none;
}

.Documentation .cards > .card > .content > .header > a:hover {
  all: unset;
}

.Documentation .field-example {
  color: #3b3b3b;
  opacity: 0.8;
  margin-top: 10px;
  display: inline-block;
  padding: 8px 8px;
  box-shadow: inset 0 0 3.5px rgba(0, 0, 0, 0.5);
}

.Documentation .field-value {
  text-decoration: underline;
  color: #404040;
}

.Documentation .field-example .field-value {
  margin-left: 14px;
  color: #242424;
}

.Documentation .field-format {
  background: #f4e6eb;
  color: #ce4072;
  font-style: normal;
  font-family: 'Share Tech Mono', monospace;
}

.Documentation .doctable {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.Documentation .doctable td,
.Documentation .doctable th {
  padding-right: 30px;
}

.Documentation .doctable thead {
  background-color: rgba(0, 0, 0, 0.1);
}

.Documentation .doctable thead th {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.Documentation .doctable thead th:nth-child(odd) {
  border-right: 1px solid white;
}

.Documentation .doctable {
  text-align: left;
}

.Documentation .doctable tbody td {
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.Documentation .doctable tbody td a {
  font-weight: normal;
}

.Documentation .schema tbody td:nth-child(even) {
  font-family: 'Share Tech Mono', monospace;
  padding-left: 7px;
}

.Documentation .doctable .note {
  opacity: 0.7;
  font-weight: normal;
  font-size: 14px;
}

.Documentation figure {
  border: 1px solid #88888355;
  margin-bottom: 40px;
}

.Documentation .figure-mobile {
  display: none;
}

.Documentation object {
  width: 100%;
}

.Documentation figcaption {
  color: #888883;
  padding-top: 2px;
  padding-bottom: 4px;
  font-size: 15px;
  text-align: center;
}

.Documentation svg {
  cursor: pointer;
}

.Documentation .noteblock {
  border: 1px solid #888883;
  padding: 10px;
  color: #474646;
  width: 100%;
}

@-webkit-keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media print {
  .Documentation,
  .Documentation .content,
  .Documentation .main,
  .Documentation .figure-mobile {
    display: block;
  }

  .Documentation .language-switch,
  .Documentation .nav,
  .Documentation .back-to-top,
  .Documentation figure:not(.figure-mobile),
  .Documentation .header-wrapper a,
  .Documentation .Footer {
    display: none;
  }

  .Documentation .figure-mobile {
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 0;
  }

  .Documentation .figure-mobile img {
    width: 100%;
  }
}

@media only screen and (max-width: 1082px) and (min-width: 782px) {
  .Documentation .Footer .right.content {
    width: 391px;
    margin-left: 40px;
    margin-top: 30px;
    display: block;
    float: none;
    position: static;
  }
}

@media only screen and (max-width: 782px) {
  /* body.no-scroll {
    height: 100vh;
    overflow: hidden;
  }

  .Footer.no-scroll {
    display: none;
  } */

  .Documentation {
    display: block;
  }

  .Documentation .Header #developer-header {
    font-size: 25px;
  }

  .Documentation .content {
    display: block;
  }

  .Documentation .main {
    padding-top: 100px;
  }

  .Documentation .language-switch {
    right: 20px;
  }

  .Documentation .nav {
    position: fixed;
    height: 100vh;
    width: 100%;
    /* height: auto; */
    min-height: 100%;
    overflow: scroll;
    top: 0;
    left: 0;
    margin: 0;
    padding-left: 10px;
    padding-top: 20px;
    padding-right: 10px;
    z-index: 5000;
    background-color: white;
  }

  .Documentation .nav-content {
    height: 100%;
    -webkit-animation: Show 0.3s;
            animation: Show 0.3s;
    padding-top: 0 !important;
    margin-top: 20px;
  }

  .Documentation .nav .nav-content {
    margin-top: 20px;
  }

  .Documentation .nav-mobile-hidden {
    display: none;
  }

  .Documentation .nav-toggle {
    z-index: 5001;
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: inline-block;
  }

  .Documentation .nav-toggle button {
    background-color: #00aeef !important;
    font-size: 18px;
    color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    height: 45px;
    border: 0;
    width: 45px;
    border-radius: 50%;
  }

  .Documentation .nav-toggle button:hover {
    opacity: 0.9;
  }

  .Documentation .example.tree {
    padding: 20px 0;
  }

  .Documentation .example.tree li {
    margin-left: 0;
  }

  .Documentation .example.tree ul {
    padding-left: 20px;
    font-size: 14px;
  }

  .Documentation .doctable td {
    padding-right: 5px;
  }
}

@media only screen and (max-width: 1784px) {
  .Documentation .cards .card {
    height: 280px;
  }

  .Documentation .cards .card .content .header {
    font-size: 18px !important;
  }

  .Documentation .cards > .card .description {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 1270px) {
  .Documentation .cards {
    display: block;
  }

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 90%;
    max-width: 400px;
    height: auto;
    display: block;
  }
}

@media only screen and (max-width: 1175px) {
  .Documentation {
    grid-template-columns: 70% 30%;
  }

  .Documentation .nav .nav-content .nav-list button {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 920px) {
  .Documentation {
    grid-template-columns: 65% 35%;
  }
}

@media only screen and (min-width: 560px) and (max-width: 782px) {
  .Documentation .nav {
    padding-left: 40px !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 340px) {
  /* small phones */

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 50%;
    max-width: 180px;
    height: auto;
    display: block;
  }
}

@media only screen and (min-width: 340px) and (max-width: 360px) {
  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 80%;
    max-width: 180px;
    height: auto;
    display: block;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 365px) {
  /* usual phones */

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 80%;
    max-width: 180px;
    height: auto;
    display: block;
    padding-right: 60px;
  }
}

@media only screen and (min-width: 365px) and (max-width: 380px) {
  /* usual phones */

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 80%;
    max-width: 180px;
    height: auto;
    display: block;
    padding-right: 70px;
  }

  .Documentation .cards > .card > .image {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 1rem;
  }

  .Documentation .cards > .card > .content {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 1rem;
  }
}

@media only screen and (min-width: 380px) and (max-width: 395px) {
  /* usual phones */

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 80%;
    max-width: 180px;
    height: auto;
    display: block;
    padding-right: 80px;
  }

  .Documentation .cards > .card > .image {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 1rem;
  }

  .Documentation .cards > .card > .content {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 1rem;
  }
}

@media only screen and (min-width: 395px) and (max-width: 460px) {
  /* usual phones */

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 80%;
    max-width: 180px;
    height: auto;
    display: block;
    padding-right: 90px;
  }

  .Documentation .cards > .card > .image {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 2rem;
  }

  .Documentation .cards > .card > .content {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 2rem;
  }
}

@media only screen and (min-width: 780px) and (max-width: 880px) {
  /* Tablets */

  .Documentation .cards .card {
    margin-bottom: 40px;
    max-width: 330px;
    height: auto;
    display: block;
    padding-right: 90px;
  }

  .Documentation .cards > .card > .image {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 2rem;
  }

  .Documentation .cards > .card > .content {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 2rem;
  }
}

.example {
  position: relative;
}

.example .actions {
  position: absolute;
  top: 15px;
  right: 15px;
}

.example .actions .far,
.example .actions .fas {
  font-size: 18px;
}

.example .actions > * {
  padding: 0 15px;
}

.example .actions button {
  all: unset;
}

.example .actions a,
.example .actions button {
  color: #353f55;
  transition: color 0.5s;
  cursor: pointer;
}

.example .actions a:hover,
.example .actions button:hover {
  /* color: #B56357; */
  transition: color 0.5s;
}

.example .mail-subject {
  margin-top: 0;
}

.example #copiedNotification {
  color: rgba(0, 128, 0, 0.76);
  text-align: center;
  height: 10px;
  opacity: 0;
  margin-top: 10px;
}

.example #copiedNotification.show {
  -webkit-animation: Show 0.3s;
          animation: Show 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.example #copiedNotification.disappeared {
  opacity: 1;
  -webkit-animation: Disappear 1.8s;
          animation: Disappear 1.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes Disappear {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

@keyframes Disappear {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.Header .header-content {
  font-family: 'Roboto Slab';
  color: #fff;
  opacity: 1;
  font-weight: 600;
  padding-left: 3%;
  text-align: left;
  width: 70%;
  padding-top: 63px;
}

.Header .header-content .Header .header-content a {
  color: #fff;
  font-size: 35px;
  position: relative;
}

.Header {
  height: 130px;
  width: 100%;
  background-color: #003476;
  z-index: 2;
  display: block;
  position: absolute;
  top: 0;
}

.header-border {
  position: absolute;
  top: 130px;
  width: 100%;
  height: 4px;
  background: linear-gradient(358.5deg, #00aeef 0.09%, #134a86);
}

.Header .logged_in_info {
  font-weight: 700;
  color: #353f55;
}

.Header .logged_in_info + .row {
  margin-top: 15px;
}

.Header .user-menu.mobile {
  display: none;
}

#mobile-menu {
  display: none;
}

.Header .user-menu.full {
  position: absolute;
  right: 0;
  top: 100px;
  padding-right: 10px;
}

.Header .user-menu.full #clientName {
  margin-right: 13px;
}

.Header .user-menu.full #client-name span {
  /* color: #dcdcdc; */
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.Header .user-menu.full span {
  font-size: 14px;
  color: #3a4252;
  font-weight: 700;
}

.Header .user-menu.full .fas {
  margin-right: 5px;
}

#clientName span {
  color: rgb(220, 220, 220);
}

.user-menu {
  z-index: 2;
}
.user-menu a,
.user-menu a span {
  color: #00aeef !important;
}

.logo-area {
  font-size: 35px;
  font-weight: 600;
  position: relative;
  bottom: 15px;
}

.img-logo {
  height: 55px;
  margin-right: 1px;
}

#img-minedu {
  position: relative;
  top: 10px;
}

.logo-text {
  font-size: 25px;
  color: white;
  font-weight: 500;
}

.logo-split {
  border-right: 2px solid white;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

#img-govgr {
  width: 100px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.language-switch {
  position: absolute;
  color: #fff !important;
  z-index: 3;
  width: 150px;
  text-align: right;
  top: 50px;
  right: 20px;
  font-weight: bold;
}

.language-switch button {
  color: #fff !important;
}

.language-switch button:hover {
  color: #00aeef !important;
  border-bottom: 2px solid #00aeef;
}

.language-switch button.active {
  border-bottom: 2px solid #00aeef;
  color: #00aeef !important;
}

.language-switch-mobile {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .Header .user-menu.full {
    display: none;
  }

  .Header .user-menu.mobile {
    display: block;
    position: absolute;
    top: 80px;
    right: 3px;
    padding-right: 20px;
    padding-top: 10px;
    z-index: 4;
    background-color: #003476;
  }

  .language-switch-mobile {
    display: inline-block;
  }

  .Header .menu-button {
    padding: 0px;
    text-align: center;
    cursor: pointer;
  }

  .Header .menu-button .fas {
    transition: color 0.3s;
    color: #ffffff;
    font-size: 25px;
  }

  .Header .menu-button:hover .fas {
    color: #00aeef;
    transition: color 0.3s;
  }

  #mobile-menu {
    position: absolute;
    background-color: #003476;
    width: 50%;
    right: 0;
    top: 133px;
    display: block;
    max-width: 340px;
  }

  #mobile-menu ul {
    list-style: none;
    padding-left: 0;
    text-align: center;
    min-height: 140px;
    min-width: 180px;
  }

  #mobile-menu li {
    color: #fff;
    display: block;
    padding: 10px 30px;
  }

  #mobile-menu li a {
    color: #fff;
  }

  #mobile-menu li:first-child {
    padding: 20px 30px 30px 30px;
  }

  #mobile-menu li:last-child {
    padding: 10px 30px 20px 30px;
  }

  #mobile-menu li:nth-last-child(2)::after {
    content: '';
    position: relative;
    padding: 10px;
    border-bottom: 1px solid white;
    width: 70%;
    display: block;
    margin: 0 auto;
  }

  .language-switch button.active,
  #mobile-menu .liLogout a:hover {
    color: #00aeef !important;
    border-bottom: 2px solid #00aeef;
    background: none;
    transition: color 0.3s;
  }

  #eDiplomasLogo {
    position: relative;
    display: block;
    left: 0;
    top: -30px;
    width: 200px;
  }

  #img-minedu {
    width: 230px;
    height: auto;
    margin: 1px auto;
    padding-right: 15px;
    left: 0;
    top: -27px;
  }

  .Header .logo-split {
    display: none;
  }
  /* .Header #img-govgr {
    width: 100px;
    height: auto;
    position: absolute;
    top: 30%;
    right: 1%;
  } */
}

@media screen and (max-width: 780px) {
  .Header .logo-split {
    display: none;
  }
  #eDiplomasLogo {
    position: relative;
    display: block;
    left: 0;
    top: -30px;
    width: 200px;
  }

  #img-minedu {
    width: 220px;
    height: auto;
    margin: 1px auto;
    padding-right: 15px;
    left: 0;
    top: -25px;
  }
  #mobile-menu ul {
    min-width: 150px;
    min-height: 80px;
  }
  .img-logo {
    height: 40px;
  }

  .logo-area {
    font-size: 35px;
    bottom: 5px;
  }
  .logo-text {
    font-size: 22px;
  }
}

@media only screen and (max-width: 550px) {
  /* medium phones */

  #eDiplomasLogo {
    position: relative;
    display: block;
    left: 0;
    top: -30px;
    width: 200px;
  }

  #img-minedu {
    width: 200px;
    height: auto;
    margin: 1px auto;
    padding-right: 15px;
    left: 0;
    top: -25px !important;
  }

  .logo-split {
    display: none;
  }
}

@media screen and (max-width: 300px) {
  #mobile-menu ul {
    min-width: 120px;
  }
  .Header .user-menu.mobile {
    display: block;
    position: absolute;
    right: 0;
  }

  #img-minedu {
    width: 200px;
    top: -20px;
  }
  .logo-area {
    font-size: 20px;
  }
}

.language-switch button {
  border: none;
  background-color: transparent;
  color: #353f55;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  padding: 0 5px;
  padding-bottom: 3px;
  margin: 0 8px;
  outline: none;
  z-index: 3;
}

.language-switch button:hover {
  color: #f1f1f1;
  border-bottom: 2px solid #fdfdfd;
  transition: all 0.3s;
}

.language-switch {
  position: absolute;
  z-index: 500;
  width: 150px;
  text-align: right;
  top: 70px;
  right: 2%;
  font-weight: 500;
  font-size: 0.9em;
}

.language-switch-mobile {
  position: relative;
  top: 0;
  right: 0;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.language-switch button.active,
.language-switch button:hover {
  color: #00aeef !important;
  border-bottom: 2px solid #00aeef;
  background: none;
}

