.CompactDiploma table {
  width: 100%;
  table-layout: fixed;
}

.CompactDiploma .side_description {
  width: 20px;
}

.CompactDiploma table.inner-table strong {
  color: #002452;
}

.CompactDiploma table.inner-table {
  border: 1px solid rgb(150, 150, 150);
  border-collapse: initial;
  border-spacing: 0;
}

.CompactDiploma table.inner-table tr td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
}

.CompactDiploma table.inner-table tr:not(:first-child) td {
  /* border-top: 1px solid rgb(150, 150, 150); */
}

.CompactDiploma table.inner-table td:nth-child(even) {
  width: 65%;
}

.CompactDiploma #copiedNotification {
  color: rgba(0, 128, 0, 0.76);
  font-size: 15px;
  text-align: left;
  height: 10px;
  opacity: 0;
  margin-top: 5px;
  padding-bottom: 10px;
}

.CompactDiploma #copiedNotification.show {
  animation: Show 0.3s;
  animation-fill-mode: forwards;
}

.CompactDiploma #copiedNotification.disappeared {
  opacity: 1;
  animation: Disappear 1.8s;
  animation-fill-mode: forwards;
}

.CompactDiploma #textSignatureRow td {
  padding-top: 7px;
  padding-bottom: 20px;
}
