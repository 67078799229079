.Terms #term-list {
  width: auto;
  max-width: 400px;
  margin: 0 auto;
  display: inline-block;
  text-align: left;
  list-style: disclosure-closed;
  margin-top: 40px;
}

.Terms #term-list li {
  margin-bottom: 16px;
}

.Terms #term-list .fas {
  color: #000000;
  margin-right: 4px;
}

.Terms .buttons {
  margin-top: 30px;
}

@media only screen and (max-width: 329px) {
  .Graduate .Terms .step-description {
    padding-bottom: 20px;
  }
}
