.Graduate .ClientSelect .dropdown {
  width: 50%;
  border-radius: 0;
}

.Graduate .ClientSelect .dropdown .icon {
  border-radius: 0;
  border: none;
}

.Graduate .ClientSelect .dropdown .menu {
  border: 2px solid rgba(34, 36, 38, 0.15);
  width: calc(100%);
  margin-left: -2px;
  border-radius: 0;
  box-sizing: content-box;
}

.Graduate .ui.selection.dropdown .menu {
  min-width: 100%;
}

.ClientSelect .selected-client {
  margin-top: 32px !important;
}

.ClientSelect .client-name {
  margin-left: 20px;
  color: #a7b3a3;
  font-size: 1.3em;
}

.ClientSelect .client-name.empty {
  border-bottom: 1px solid #a7b3a3;
  min-width: 27% !important;
  display: inline-block;
  height: 21px;
}

@media only screen and (max-width: 782px) {
  .Graduate .ClientSelect .dropdown {
    width: 90%;
    max-width: 300px;
  }
}

@media only screen and (max-width: 329px) {
  .Graduate .ClientSelect .dropdown {
    width: 100%;
  }

  .ClientSelect .client-name.empty {
    min-width: 60% !important;
  }
}
