.Verifier > .input_area {
  margin-bottom: 30px;
}

.Verifier .input_area textarea {
  width: 100%;
  min-height: 150px;
}

.Verifier > .action_area {
  text-align: center;
}

.Verifier > .action_area > button {
  width: 60%;
  min-width: 172px;
  margin: 0 auto;
}

.Verifier > .result_area {
  margin-top: 30px;
}

.Verifier > .result_area > .data_area {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  padding: 0 40px;
}

.Verify > .description > h3 {
  color: #353f55;
}

.circle-loader {
  --success-color: rgba(0, 130, 90, 1);
  --fail-color: rgba(220, 0, 20, 0.8);
  /* --no-match-color: rgba(0, 0, 0, 0.3); */
  --no-match-color: #ff7900;
  margin: 0 auto;
  border: 3px solid rgba(0, 0, 0, 0.3);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-left-color: rgba(0, 0, 0, 0.7);
  animation-name: loader-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  box-sizing: border-box;
  text-align: center;
  vertical-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mark {
  animation-name: opacity-animation;
  animation-duration: 1s;
  font-size: 60px;
}

.checkmark {
  color: var(--success-color) !important;
}

.failmark {
  color: var(--fail-color) !important;
}

.no-match-mark {
  color: var(--no-match-color) !important;
}

.finished {
  animation: none;
  border-left-color: rgba(0, 0, 0, 0.3);
}

.success {
  border: 3px solid var(--success-color);
}

.fail {
  border: 3px solid var(--fail-color);
}

.no-match {
  border: 3px solid var(--no-match-color);
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 530px) {
  .Verifier > .result_area > .data_area {
    padding: 0 0;
  }
}
