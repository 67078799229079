#NewsReel {
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: #e4e6f7;
  flex-wrap: wrap;
  border-radius: 15px;
  /* box-shadow: 1px 1px 0 1px rgb(137, 135, 150); */
}

#NewsReel .Main {
  display: block;
  flex: 1 0 65%;
  text-align: center;
}

#NewsReel .Main iframe {
  padding-top: 12px;
  padding-bottom: 12px;
  width: 80%;
  height: auto;
  aspect-ratio: 16 / 9;
}

h2.news {
  margin-top: 20px;
}

#NewsReel .Side {
  flex: 1 0 35%;
}

#NewsReel .Side ul {
  display: block;
  /* background-color: #7b8bc0; */
  background-color: #9aa8d3;
  margin: 0;
  height: 100%;
  list-style: none;
  padding-left: 0;
  color: rgb(43, 43, 43);
  font-weight: bold;
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 15px;
  border-left: 2px solid #dadbeb;
}

#NewsReel .Side li {
  padding-top: 10px;
  padding-bottom: 10px;
  /* border-bottom: 1px dashed rgb(78, 78, 78);; */
  /* box-shadow: 2px 2px 0 1px rgb(61, 59, 88); */
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  transition: all 0.1s;
}

#NewsReel .Side li:first-of-type {
  border-top-right-radius: 15px;
}

#NewsReel .Side li:hover {
  cursor: pointer;
  background-color: #e4e6f7;
  /* border-left: 3px solid #7b8bc0; */
}

#NewsReel .Side li span {
  padding-left: 10px;
}

#NewsReel .Side li img {
  display: inline;
  height: 80px;
  width: auto;
}

#NewsReel .Side li.active {
  background-color: #e4e6f7;
}

/* .Video:first-of-type {
    box-shadow: 1px 0 0 0 #cfcfcf;
  } */

.Video h4 {
  /* background-color: #e2e2e2; */
  width: auto;
  display: block;
  padding: 5px;
  color: #505364;
  font-size: 15px;
}

#NewsReel .TextEntry {
  text-align: left;
  display: block;
  padding-left: 40px;
}

@media only screen and (max-width: 768px) {
  #NewsReel {
    border-radius: 0;
  }

  #NewsReel .Main iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    padding-top: 0;
  }

  #NewsReel .Side ul {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  #NewsReel .Side li:first-of-type {
    border-top-right-radius: 0;
  }
}
