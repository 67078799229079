.Graduate.Page {
  height: auto;
  position: absolute;
  background-color: #fafafa;
}

.Graduate .container {
  background-color: #fff;
  margin-top: 160px;
  min-height: 60vh;
  width: 55vw;
  margin-bottom: 80px;
  padding-top: 30px;
  padding-bottom: 80px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: Show 0.4s;
  animation-fill-mode: forwards;
  position: static;
  margin: 160px auto;
}

.Organisation .container > * {
  animation: Show 0.4s;
}

.Graduate .Header {
  background-color: #003476;
  position: absolute;
}

.ClientSelect .client-name {
  color: #555;
}

.Graduate .steps-wrapper {
  margin-bottom: 40px;
}

.Graduate .ui.steps {
  text-align: left !important;
  width: 100%;
  border-radius: 0.1rem !important;
}

.Graduate .ui.steps .step {
  width: 30%;
}

.Graduate .ui.steps .content {
  color: #353f55;
}

.Graduate .ui.steps .step:before {
  color: #a7b3a3 !important;
  padding-bottom: 4px;
}

.Graduate .ui.steps .step.active,
.Graduate .ui.steps .step.active:after {
  background-color: #f5f1f1 !important;
}

.Graduate .step-description {
  padding-bottom: 25px;
}

.Graduate .container-header {
  margin-bottom: 50px;
}

.Graduate h2 {
  color: #003476;
}

.Graduate .buttons {
  margin: 0 auto;
  text-align: center;
  margin-top: 70px;
}

.Graduate .buttons button {
  width: auto;
}

.Graduate .buttons button:nth-child(1) {
  margin-right: 14%;
}

.Graduate .buttons button:nth-child(2) {
  margin-left: 14%;
}

.Graduate .fas {
  color: #003476;
}

.Graduate .back-link {
  font-size: 17px;
}

.Graduate .Menu .selection {
  background-image: linear-gradient(
    #fafafa,
    #fafafa 50%,
    hsla(0, 0%, 85.5%, 0.288) 0,
    hsla(0, 0%, 85.5%, 0.288)
  ) !important;
}

.fancy .fas {
  color: #fff;
}

@media only screen and (max-width: 1048px) {
  .Graduate .ui.container {
    width: 80vw;
  }
}

@media only screen and (max-width: 500px) {
  .Graduate .ui.container {
    margin-top: 90px;
  }
}

@media only screen and (max-width: 480px) {
  .Graduate.Page {
    background-color: #fff;
  }
  .Graduate .container {
    background-color: #fff;
    margin-top: 160px;
    min-height: 83vh;
    width: 100vw;
    margin-bottom: 80px;
    padding-top: 30px;
    padding-bottom: 80px;
    box-shadow: none;
    opacity: 0;
    animation: Show 0.4s;
    animation-fill-mode: forwards;
    position: static;
    margin: 100px auto;
  }
}

@media only screen and (min-width: 480px) and (max-width: 900px) {
  .Graduate.Page {
    background-color: #fff;
  }
  .Graduate .container {
    background-color: #fff;
    margin-top: 160px;
    min-height: 83vh;
    width: 100vw;
    margin-bottom: 80px;
    padding-top: 30px;
    padding-bottom: 80px;
    box-shadow: none;
    opacity: 0;
    animation: Show 0.4s;
    animation-fill-mode: forwards;
    position: static;
    margin: 100px auto;
  }
}

@media only screen and (max-width: 329px) {
  .Graduate .ui.container {
    width: 90vw !important;
  }

  .Graduate .buttons {
    margin-top: 40px;
  }

  .Graduate .buttons .button,
  .Graduate .buttons .back-link {
    display: block;
  }

  .Graduate .buttons .back-link {
    position: relative;
    top: 120px;
  }

  .Graduate .buttons button:nth-child(2) {
    display: block;
    margin: 0 auto;
  }
}
