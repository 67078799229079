.TermsOfUse {
  min-height: 100vh;
  background-color: #fff;
}

.TermsOfUse > * {
  animation: Show 1s;
}

.TermsOfUse > .content {
  width: 70%;
  min-height: 90vh;
  padding: 150px 0;
  margin: 0 auto;
  position: relative;
}

.TermsOfUse > .content .header {
  color: #003476;
  font-size: 2.7em;
  margin-bottom: 40px;
}

.TermsOfUse ol#termsOfUseList li {
  counter-increment: list;
  list-style-type: none;
  position: relative;
  font-size: 17px;
  margin: 40px auto;
}

.TermsOfUse ol#termsOfUseList li p {
  text-indent: 0px;
  margin-left: 10px;
}

.TermsOfUse ol#termsOfUseList li:before {
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  background-color: #003476;
  color: rgb(252, 252, 246);
  border-radius: 50%;
  height: 26px;
  width: 26px;
  padding-top: 3.5px;
  padding-left: 9px;
  content: counter(list);
  left: -32px;
  position: absolute;
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  1000% {
    opacity: 1;
  }
}

@media only screen and (max-width: 762px) {
  .TermsOfUse > .content {
    width: 90%;
    min-height: 90vh;
    padding: 100px 0;
    margin: 0 auto;
    position: relative;
    color: #353f55;
  }

  .TermsOfUse > .content .header {
    color: #a7b3a5;
    font-size: 2em;
    margin-bottom: 40px;
    margin-top: 50px;
  }
}
