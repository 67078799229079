html {
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
body::-webkit-scrollbar {
  width: 0 !important;
}

#developer-header {
  font-size: 30px;
}

.Documentation .header {
  margin-top: 60px;
}

.Documentation .cards > .card .fa {
  color: #00aeef;
  font-size: 80px;
}

.Documentation .cards > .card:hover {
  border: 1px solid #003476 !important;
}

.Documentation .nav .header {
  margin-top: 20px;
  color: #000 !important;
}

.Documentation .language-switch {
  right: 10px;
}

.Documentation #img-govgr {
  right: 10px;
}

.Documentation {
  background-color: #fff;
  display: grid;
  grid-template-areas: 'content nav';
  grid-template-columns: 75% 25%;
}

.Documentation > * {
  animation: Show 1s;
}

.Documentation .hidden {
  display: none;
}

.Documentation .block {
  display: block;
}

.Documentation .content {
  font-size: 17px;
  grid-area: content;
}

.Documentation > .content {
  box-sizing: content-box;
  overflow-x: hidden;
  width: 100%;
}

.Documentation .main {
  padding: 150px 0;
  width: 85%;
  margin: 0 auto;
  margin-top: 60px;
}

.Documentation .language-switch {
  right: 20px;
}

.Documentation .main a {
  font-weight: bold;
  text-decoration: underline;
}

.Documentation .nav {
  grid-area: nav;
  padding-right: 30px;
  background-color: #f2f2f2;
  border-left: 1px solid #d8d8d8;
  z-index: 1;
}

.Documentation .nav button {
  background: transparent;
  border: none;
  color: #686868;
  font-weight: bold;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
}

.Documentation .nav button .fas {
  margin-left: 7px;
  font-size: 15px;
}

.Documentation .nav .nav-list {
  margin-top: 50px;
  padding-bottom: 20px;
}

.Documentation .nav .nav-list > li {
  padding-bottom: 20px;
}

.Documentation .nav .category {
  margin-bottom: 10px;
}

.Documentation .nav .category.collapsed {
  height: 50px;
}

.Documentation .nav .category .contents {
  max-height: 1000px;
  display: inline-block;
  opacity: 1;
}

.Documentation .nav .category.collapsed .contents {
  max-height: 0;
  display: none;
  pointer-events: none;
  opacity: 0;
}

.Documentation .nav .category.collapsed button .fa-chevron-up {
  transform: rotate(180deg);
  transition: transform 0.1s;
}

.Documentation .category .fa-link {
  color: #353f55;
  padding-left: 10px;
}

.Documentation .nav .nav-content-wrapper {
  position: sticky;
  top: 0;
}

.Documentation .nav .nav-content {
  height: 100vh;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-top: 150px;
  overflow-y: scroll;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.Documentation .nav .nav-content::-webkit-scrollbar {
  width: 0 !important;
}

.Documentation .nav ul {
  list-style: none;
  padding-left: 0;
}

.Documentation .nav li a {
  /*border-bottom: 2px solid #353f55;*/
  padding-bottom: 4px;
  font-weight: 800;
  line-height: 2.1em;
  width: 100%;
}

.Documentation .contents > li a h4 {
  display: inline;
  width: 100%;
  border-bottom: 2px solid #353f55;
  padding-bottom: 4px;
}

.Documentation .contents > li a.active h4,
.Documentation .contents > li a:hover h4 {
  border-bottom: 2px solid #00aeef;
}

.Documentation .section > li a {
  border: none;
}

.Documentation .nav .subsection a {
  display: block;
}

.Documentation .nav li a:hover {
  /* border-color: #B56357; */
  transition: all 0.2;
}

.Documentation .nav a {
  transition: all 0.4s;
}

.Documentation .nav a.active {
  color: #00aeef;
  /* border-color: #B56357; */
}

.Documentation .nav li.inner a {
  border: none;
  font-weight: normal;
  line-height: 1.4285em;
}

.Documentation .nav .li {
  margin-bottom: 8px;
}

.Documentation .nav .section {
  margin: 7px 0;
  margin-left: 20px;
}

.Documentation .nav .section > li {
  margin-top: 20px;
}

.Documentation .nav .subsection {
  margin: 15px 0;
  margin-left: 15px;
  list-style-type: disc;
}

.Documentation .nav h6 {
  margin: 0;
}

.Documentation p,
.Documentation li {
  color: #3a3a3a;
}

.Documentation ul {
  margin: 30px 0;
}

.Documentation li {
  margin-bottom: 14px;
}

.Documentation h1.header {
  color: #353f55;
  font-size: 2em;
  margin-bottom: 40px;
  grid-area: header;
}

.Documentation h2.header {
  color: #000;
}

.Documentation .header-wrapper .header,
.Documentation .header-wrapper .subheader {
  display: inline-block;
}

.Documentation [data-tooltip]:before {
  display: none;
}

.Documentation [data-tooltip]:after {
  opacity: 0.8;
}

.Documentation .header-wrapper a {
  margin-left: 8px;
  opacity: 0.5;
}

.Documentation .header-wrapper a:hover {
  opacity: 1;
}

.Documentation .content .section {
  margin-bottom: 55px;
}

.Documentation .content .section.inner .section.inner {
  margin-bottom: 40px;
}

.Documentation .content .section.inner {
  margin-bottom: 50px;
}

.Documentation h3.subheader {
  color: #000;
  font-size: 1.4em;
  margin-bottom: 22px;
}

.Documentation h4.subheader {
  color: #727272;
  font-size: 1.3em;
  border-bottom: 2px solid #727272;
  padding-bottom: 5px;
  display: inline-block;
}

.Documentation h5.subheader {
  font-size: 1.14rem;
}

.Documentation .example {
  background-color: #f1f1f1;
  border: 1px solid #e5e5e5;
  padding: 20px 15px;
  margin: 30px 0;
  color: rgba(64, 64, 64, 0.9);
  font-family: 'Share Tech Mono', monospace;
}

.Documentation .example {
  word-break: break-all;
}

.Documentation .example span {
  margin: 0;
}

.Documentation .example.code {
  font-size: 16px;
}

.Documentation .example.tree {
  padding: 20px 30px;
}

.Documentation .example.tree li {
  margin-left: 20px;
}

.Documentation .indented {
  margin-left: 2% !important;
  display: block !important;
}

.Documentation .indented p {
  margin-bottom: 0.5em;
}

.Documentation .code .word:nth-child(odd) {
  color: #405da6;
}

.Documentation .code .word:nth-child(even) {
  color: #cf1d41;
}

.Documentation .highlight {
  background: #f4e6eb;
  color: #ce4072;
  font-style: normal;
  font-family: 'Share Tech Mono', monospace;
}

.Documentation .example br {
  margin-bottom: 12px;
}

.Documentation .tag:before {
  content: '<';
}

.Documentation .tag:after {
  content: '>';
}

.Documentation .cursor:before {
  content: '|';
}

.Documentation .nav-toggle {
  display: none;
}

.Documentation .back-to-top {
  text-align: center;
}

.Documentation .back-to-top a {
  font-size: 19px;
}

.Documentation .cards {
  margin-top: 60px;
  margin-bottom: 60px;
}

.Documentation .cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
}

.Documentation .cards > .card {
  border: 2px solid #cfcfcf;
  border-radius: 15px;
  padding: 4rem;
  padding-bottom: 1rem;
  padding-top: 2rem;
  text-align: center;
  font-size: 15px;
}

.Documentation .cards > .card > .content > .description {
  text-align: center;
  margin-top: 1rem;
  color: #3a3a3a;
}

.Documentation .cards > .card:hover {
  border: 1px solid #353f55 !important;
  transition: border 0.5s !important;
  cursor: pointer !important;
}

.Documentation .cards > .card > .content > .header > a {
  text-decoration: none;
}

.Documentation .cards > .card > .content > .header > a:hover {
  all: unset;
}

.Documentation .field-example {
  color: #3b3b3b;
  opacity: 0.8;
  margin-top: 10px;
  display: inline-block;
  padding: 8px 8px;
  box-shadow: inset 0 0 3.5px rgba(0, 0, 0, 0.5);
}

.Documentation .field-value {
  text-decoration: underline;
  color: #404040;
}

.Documentation .field-example .field-value {
  margin-left: 14px;
  color: #242424;
}

.Documentation .field-format {
  background: #f4e6eb;
  color: #ce4072;
  font-style: normal;
  font-family: 'Share Tech Mono', monospace;
}

.Documentation .doctable {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.Documentation .doctable td,
.Documentation .doctable th {
  padding-right: 30px;
}

.Documentation .doctable thead {
  background-color: rgba(0, 0, 0, 0.1);
}

.Documentation .doctable thead th {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.Documentation .doctable thead th:nth-child(odd) {
  border-right: 1px solid white;
}

.Documentation .doctable {
  text-align: left;
}

.Documentation .doctable tbody td {
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.Documentation .doctable tbody td a {
  font-weight: normal;
}

.Documentation .schema tbody td:nth-child(even) {
  font-family: 'Share Tech Mono', monospace;
  padding-left: 7px;
}

.Documentation .doctable .note {
  opacity: 0.7;
  font-weight: normal;
  font-size: 14px;
}

.Documentation figure {
  border: 1px solid #88888355;
  margin-bottom: 40px;
}

.Documentation .figure-mobile {
  display: none;
}

.Documentation object {
  width: 100%;
}

.Documentation figcaption {
  color: #888883;
  padding-top: 2px;
  padding-bottom: 4px;
  font-size: 15px;
  text-align: center;
}

.Documentation svg {
  cursor: pointer;
}

.Documentation .noteblock {
  border: 1px solid #888883;
  padding: 10px;
  color: #474646;
  width: 100%;
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media print {
  .Documentation,
  .Documentation .content,
  .Documentation .main,
  .Documentation .figure-mobile {
    display: block;
  }

  .Documentation .language-switch,
  .Documentation .nav,
  .Documentation .back-to-top,
  .Documentation figure:not(.figure-mobile),
  .Documentation .header-wrapper a,
  .Documentation .Footer {
    display: none;
  }

  .Documentation .figure-mobile {
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 0;
  }

  .Documentation .figure-mobile img {
    width: 100%;
  }
}

@media only screen and (max-width: 1082px) and (min-width: 782px) {
  .Documentation .Footer .right.content {
    width: 391px;
    margin-left: 40px;
    margin-top: 30px;
    display: block;
    float: none;
    position: static;
  }
}

@media only screen and (max-width: 782px) {
  /* body.no-scroll {
    height: 100vh;
    overflow: hidden;
  }

  .Footer.no-scroll {
    display: none;
  } */

  .Documentation {
    display: block;
  }

  .Documentation .Header #developer-header {
    font-size: 25px;
  }

  .Documentation .content {
    display: block;
  }

  .Documentation .main {
    padding-top: 100px;
  }

  .Documentation .language-switch {
    right: 20px;
  }

  .Documentation .nav {
    position: fixed;
    height: 100vh;
    width: 100%;
    /* height: auto; */
    min-height: 100%;
    overflow: scroll;
    top: 0;
    left: 0;
    margin: 0;
    padding-left: 10px;
    padding-top: 20px;
    padding-right: 10px;
    z-index: 5000;
    background-color: white;
  }

  .Documentation .nav-content {
    height: 100%;
    animation: Show 0.3s;
    padding-top: 0 !important;
    margin-top: 20px;
  }

  .Documentation .nav .nav-content {
    margin-top: 20px;
  }

  .Documentation .nav-mobile-hidden {
    display: none;
  }

  .Documentation .nav-toggle {
    z-index: 5001;
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: inline-block;
  }

  .Documentation .nav-toggle button {
    background-color: #00aeef !important;
    font-size: 18px;
    color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    height: 45px;
    border: 0;
    width: 45px;
    border-radius: 50%;
  }

  .Documentation .nav-toggle button:hover {
    opacity: 0.9;
  }

  .Documentation .example.tree {
    padding: 20px 0;
  }

  .Documentation .example.tree li {
    margin-left: 0;
  }

  .Documentation .example.tree ul {
    padding-left: 20px;
    font-size: 14px;
  }

  .Documentation .doctable td {
    padding-right: 5px;
  }
}

@media only screen and (max-width: 1784px) {
  .Documentation .cards .card {
    height: 280px;
  }

  .Documentation .cards .card .content .header {
    font-size: 18px !important;
  }

  .Documentation .cards > .card .description {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 1270px) {
  .Documentation .cards {
    display: block;
  }

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 90%;
    max-width: 400px;
    height: auto;
    display: block;
  }
}

@media only screen and (max-width: 1175px) {
  .Documentation {
    grid-template-columns: 70% 30%;
  }

  .Documentation .nav .nav-content .nav-list button {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 920px) {
  .Documentation {
    grid-template-columns: 65% 35%;
  }
}

@media only screen and (min-width: 560px) and (max-width: 782px) {
  .Documentation .nav {
    padding-left: 40px !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 340px) {
  /* small phones */

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 50%;
    max-width: 180px;
    height: auto;
    display: block;
  }
}

@media only screen and (min-width: 340px) and (max-width: 360px) {
  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 80%;
    max-width: 180px;
    height: auto;
    display: block;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 365px) {
  /* usual phones */

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 80%;
    max-width: 180px;
    height: auto;
    display: block;
    padding-right: 60px;
  }
}

@media only screen and (min-width: 365px) and (max-width: 380px) {
  /* usual phones */

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 80%;
    max-width: 180px;
    height: auto;
    display: block;
    padding-right: 70px;
  }

  .Documentation .cards > .card > .image {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 1rem;
  }

  .Documentation .cards > .card > .content {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 1rem;
  }
}

@media only screen and (min-width: 380px) and (max-width: 395px) {
  /* usual phones */

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 80%;
    max-width: 180px;
    height: auto;
    display: block;
    padding-right: 80px;
  }

  .Documentation .cards > .card > .image {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 1rem;
  }

  .Documentation .cards > .card > .content {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 1rem;
  }
}

@media only screen and (min-width: 395px) and (max-width: 460px) {
  /* usual phones */

  .Documentation .cards .card {
    margin-bottom: 40px;
    width: 80%;
    max-width: 180px;
    height: auto;
    display: block;
    padding-right: 90px;
  }

  .Documentation .cards > .card > .image {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 2rem;
  }

  .Documentation .cards > .card > .content {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 2rem;
  }
}

@media only screen and (min-width: 780px) and (max-width: 880px) {
  /* Tablets */

  .Documentation .cards .card {
    margin-bottom: 40px;
    max-width: 330px;
    height: auto;
    display: block;
    padding-right: 90px;
  }

  .Documentation .cards > .card > .image {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 2rem;
  }

  .Documentation .cards > .card > .content {
    text-align: center;
    margin-top: 1rem;
    color: #3a3a3a;
    padding-left: 2rem;
  }
}
