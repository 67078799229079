.Check > .description > h3 {
  color: #353f55;
}

.collapse-steps {
  margin-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.collapse-steps > .step {
  border: 1px solid rgba(120, 120, 120, 0.5);
  padding-left: 15px;
  padding-right: 15px;
}

.collapse-steps > .step > .header {
  font-size: 18px;
  padding-top: 10px;
  position: relative;
}

.collapse-steps > .step:not(.opened) > .header {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 6px;
}

.collapse-steps > .step > .header > div > button {
  background: 0;
  padding-left: 0;
  padding-right: 0;
  float: right;
  position: absolute;
  top: 5px;
  border: 0;
  right: 0;
}

.collapse-steps > .step > .header > div > button:hover {
  color: #003476;
  transition: color 0.5s;
}
.collapse-steps > .step > .header > .icon {
  font-size: 16px;
  padding-bottom: 2px;
}

.collapse-steps > .step.opened > .header {
  font-weight: 800;
}

.step-content {
  height: 0;
  overflow: hidden;
  transition: padding 0.3s;
}

.step.opened > .step-content {
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.step.completed .before-completion,
.step.uncompleted .after-completion {
  display: none;
}

.step.completed .after-completion,
.step.uncompleted .before-completion {
  display: initial;
}

.step.completed {
  opacity: 0.75;
  background-color: #f4f4f4;
}

.step.closed > .header > span {
  opacity: 0.7;
}

.step-content > .form {
  max-width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.step-content > .form > .field > .input > input {
  font-size: 18px;
}

.step-content > .form > .field > .ssn > input {
  padding-left: 20px;
}

.dropdown-wrapper {
  display: inline-flex;
}

.dropdown-wrapper > .label {
  margin-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 15px;
}

.step-content > .data_area {
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
}

.step-content > .data_area > .button-area {
  margin-bottom: 10px;
  margin-left: 23px;
  position: relative;
}

.step-content > .data_area > .button-area .ui.button {
  width: calc(50% - 5.5px);
  margin-right: 2px;
  margin-left: 2px;
}

.step-content > .data_area > .button-area .ui.button:nth-of-type(2) {
  margin-right: 0;
}

.step-content .permission-box {
  margin-top: 50px;
  text-align: left;
  display: flex;
  margin-bottom: 24px;
}

.step-content .permission-box input {
  align-self: flex-start;
  margin-right: 10px;
}

.step-content .error {
  color: rgb(116, 44, 60);
  font-weight: bold;
  top: 10px;
  position: relative;
  display: inline;
}

.step-content .error.hidden {
  display: none;
}

#date-wrapper .error {
  opacity: 1;
  transition: all 0.4s;
}

#date-wrapper .error.hidden {
  display: block;
  opacity: 0;
  transition: all 0.8s;
}

#date-wrapper {
  margin-bottom: 0.8em;
}

.label abbr {
  display: inline;
}

.label .fas {
  font-size: 14px;
  display: inline;
  font-weight: normal;
  position: absolute;
  left: 1px;
}

table.inner_table td:nth-child(even) {
  width: 65%;
}

.step-content .signature button {
  width: 40%;
}

.ui.action.input:not([class*='left action']) > input.signature-input {
  width: 60%;
}

.less-visible {
  opacity: 0.8;
}

#downloadPdf {
  display: inline;
}

#downloadPdf button,
#downloadPrintable button {
  padding: 10px 0;
}

#downloadPdf input {
  display: none !important;
}

#downloadPrintable {
  display: inline;
}

#downloadPrintable input {
  display: none !important;
}

.light-success {
  font-size: 15px;
  font-weight: 400;
  margin: 10px 0 0 18px;
}

.light-failure {
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
}
@media only screen and (max-width: 1100px) {
  .collapse-steps > .step > .header > div > button {
    float: none;
    position: static;
    margin-top: 10px;
    margin-left: calc(100% - 70px);
  }
}

@media only screen and (max-width: 700px) {
  .step-content > .form {
    box-sizing: border-box;
    width: 100%;
  }

  .step-content .form .ui.input {
    display: inline;
  }

  .step-content .form .ui.input input {
    display: block;
    width: 100% !important;
    border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
    border-bottom-left-radius: 0.28571429rem !important;
    font-size: 15px;
  }

  .step-content .form .ui.input input {
    display: block;
    width: 100% !important;
    border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
    border-bottom-left-radius: 0.28571429rem !important;
    font-size: 15px;
  }

  .step-content .form .ui.input input:focus {
    border-left-color: #85b7d9 !important;
  }

  .step-content .field {
    line-height: 0.8em;
    width: 100%;
  }

  .step-content .form .ui.label {
    border-top-right-radius: 0.28571429rem !important;
    border-bottom-left-radius: 0 !important;
    display: inline-block;
    width: auto;
    font-size: 16px;
  }

  .step-content .form .ui.buttons {
    display: block;
    width: 100% !important;
  }

  .step-content .form .ui.buttons button {
    display: block !important;
    width: 100% !important;
  }

  .step-content .form .ui.buttons button:first-child {
    border-top-right-radius: 0.28571429rem !important;
  }

  .step-content .form .ui.buttons button:last-child {
    border-bottom-left-radius: 0.28571429rem !important;
    border-top-right-radius: 0 !important;
  }

  .step-content .field.level-pick {
    display: inline-block;
  }

  .step-content .field.level-pick {
    position: relative !important;
    width: 100% !important;
  }

  .step-content .date-field {
    display: block;
  }

  .step-content .date-field .input-area .label {
    width: 32px;
    display: inline-block;
    font-size: 16px;
  }

  .step-content .input-area {
    display: inline-flex;
  }

  input.date {
    text-align: center;
    height: 100%;
    width: calc((100% - 66px) / 2) !important;
  }

  input.date.small {
    text-align: center;
    width: calc((100% - 66px) / 4) !important;
  }

  .step-content .dropdown-wrapper {
    display: block;
  }

  .step-content .dropdown.search.selection {
    width: 100%;
    min-width: 100%;
  }

  .step-content .date-field .input-area .input {
    border-bottom-left-radius: 0.28571429rem !important;
  }

  .step-content .selection {
    border-bottom-left-radius: 0.28571429rem !important;
  }

  .collapse-steps > .step > .header > div > .button {
    float: none;
    position: static;
    margin-left: calc(100% - 80px);
  }

  .collapse-steps .open {
    font-size: 17px;
  }
}

@media only screen and (max-width: 600px) {
  .step-content .data_area {
    position: relative;
  }

  .step-content .button-area {
    display: inline-flex;
    width: 90%;
  }

  .step-content .button-area form {
    width: calc(50% - 3.5px);
  }

  .step-content .button-area form button {
    width: 96% !important;
  }

  .step-content .data_area button {
    height: 70px !important;
  }

  .step-content .signature {
    height: 50px;
    width: 100%;
  }

  .step-content .signature .not-mobile {
    display: none;
  }

  .step-content .signature .fas {
    display: inline-block;
  }

  .step-content #textSignatureRow {
    height: 134px;
  }

  .step-content .signature button {
    width: 20%;
    height: 100% !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    text-align: center !important;
  }

  .ui.action.input:not([class*='left action']) > input.signature-input {
    width: 73%;
    border-right-color: rgba(34, 36, 38, 0.15) !important;
  }
}

@media only screen and (max-width: 456px) {
  .collapse-steps {
    padding-left: 15px;
    padding-right: 15px;
  }

  #not-authorized {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 80px;
  }

  #not-authorized.hidden {
    display: none;
  }

  #check-step2 {
    padding-left: 0;
    padding-right: 0;
  }

  #check-step2 .header {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .step-content .data_area {
    width: 100%;
  }
}

@media only screen and (max-width: 390px) {
  .collapse-steps {
    padding-left: 0;
    padding-right: 0;
  }

  .collapse-steps > .step,
  #check-step2 .header {
    padding-left: 5px;
    padding-right: 5px;
  }

  .step-content > .form {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 330px) {
  .not-mobile {
    display: none;
  }

  .step-content .data_area .button-area button .fas {
    font-size: 23px;
  }

  .step-content #textSignatureRow {
    height: 150px;
  }

  .Page .content.organisationContent {
    padding-left: 10px;
    padding-right: 10px;
  }
}
