.GeneralError {
  position: static;
  overflow: auto;
}

.GeneralError .Header {
  position: absolute;
}

.GeneralError .error-body {
  padding-top: 7vh;
  min-height: 100vh;
}

.GeneralError .error-inner {
  width: 80%;
  margin: 0 auto;
  margin-top: 20vh;
}

.GeneralError .error-message {
  font-size: 40px;
  font-weight: 700;
  width: 40%;
  display: inline-block;
  line-height: 55px;
  float: left;
}

.GeneralError .error-header {
  font-size: 70px;
  margin-bottom: 30px;
}

.GeneralError a {
  font-size: 20px;
}

.GeneralError .error-icon {
  width: 60%;
  display: inline-block;
  position: relative;
}

.GeneralError .error-icon svg {
  width: 80%;
  margin-left: 10%;
  margin-top: -30px;
}

.GeneralError #tryLater {
  font-size: 30px;
  margin-top: 20px;
}
