.pill {
  font-family: 'Roboto Slab';
  display: block;
  margin-bottom: 20px;
  width: 90%;
  min-width: 350px;
  display: block;
  border-left: 4px solid rgb(170, 170, 170);
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.pill .pair .mini-header {
  font-weight: bold;
  color: gray !important;
  margin-right: 10px;
}
.pill .pill-header {
  background-color: white;
  position: relative;
  cursor: pointer;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 18px;
  box-sizing: border-box;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    border-left 0.1s ease-out;
  padding-right: 50px;
}

.pill .pill-header .pill-header-text {
  font-size: 16px;
}

.pill .pill-header .pill-header-tag {
  font-size: 16px;
  word-wrap: break-word;
  font-weight: 800;
}

.pill .pill-header:hover,
.pill.opened .pill-header {
  background: rgba(252, 252, 252);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    border-left 0.4s ease-out;
}

.pill.opened .pill-header {
  background: rgba(252, 252, 252);
  border-bottom-left-radius: 0;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out,
    border-left 0.3s ease-out;
}

.pill:not(.closed) .pill-header {
  background: rgba(248, 248, 248);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.pill .pill-header-btn {
  all: unset;
  position: absolute;
  right: 20px;
  top: 15px;
  bottom: 0;
  font-size: 20px;
  height: 30px;
  margin: auto;
}

.pill .pill-header-btn .fas {
  font-size: medium;
}

.pill.closed .pill-content {
  display: none;
}

.pill .pill-content {
  background-color: white;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 120px;
  box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.1);
}

.pill .action-area {
  text-align: right;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pill table {
  font-size: 17px;
  width: 100%;
  border-collapse: collapse;
}

.pill tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.pill td:nth-child(odd) {
  font-weight: bold;
  padding: 15px 5px;
  padding-left: 20px;
}

.pill td:nth-child(even) {
  padding: 15px 20px 15px 15px;
  word-break: break-word;
}

.pill .pair .mini-header {
  display: inline-block;
}
.pill table.scopes td {
  font-weight: 400;
}

.pill table.scopes svg {
  margin-right: 8px;
}

@media screen and (max-width: 850px) {
  .pill {
    min-width: 0 !important;
    width: 100% !important;
  }

  .pill-area {
    /* width: 95% !important; */
    margin-left: 0 !important;
  }

  .pill-area * {
    transition: none !important;
  }

  .pill.closed .pill-header:hover {
    background-color: white !important;
  }

  /*.pill.colored.closed .pill-header:hover {
        background: rgba(220, 200, 200) !important;
        border-left: 20px solid transparent !important;
    }*/
}

@media screen and (max-width: 520px) {
  .pill .pill-header {
    padding-left: 10px;
  }

  .pill .pill-header .pill-header-text {
    font-size: 15px;
  }

  .pill table {
    font-size: 14px;
    table-layout: fixed;
  }

  .pill td:nth-child(odd) {
    padding-left: 10px;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .pill td:nth-child(even) {
    text-align: right;
    padding-right: 5px;
    /* word-wrap: break-word;
        white-space: normal; */
    /* white-space:pre-wrap;
        word-break:break-word; */
  }

  .pill table.scopes td {
    padding-left: 0;
  }

  .pill .pair .mini-header {
    display: block;
  }
  .row .value {
    margin-top: 10px;
  }

  .pill td:nth-child(odd) {
    width: 36%;
  }
  .pill td:nth-child(even) {
    text-align: left;
  }

  .pill .pair .mini-header {
    display: block;
  }
}

@media screen and (max-width: 320px) {
  .pill table {
    font-size: 13px;
  }
}
