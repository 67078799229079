.language-switch button {
  border: none;
  background-color: transparent;
  color: #353f55;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  padding: 0 5px;
  padding-bottom: 3px;
  margin: 0 8px;
  outline: none;
  z-index: 3;
}

.language-switch button:hover {
  color: #f1f1f1;
  border-bottom: 2px solid #fdfdfd;
  transition: all 0.3s;
}

.language-switch {
  position: absolute;
  z-index: 500;
  width: 150px;
  text-align: right;
  top: 70px;
  right: 2%;
  font-weight: 500;
  font-size: 0.9em;
}

.language-switch-mobile {
  position: relative;
  top: 0;
  right: 0;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.language-switch button.active,
.language-switch button:hover {
  color: #00aeef !important;
  border-bottom: 2px solid #00aeef;
  background: none;
}
