.NewsPage {
  min-height: 100vh;
  background-color: #fff;
  font-size: 18px;
}
.NewsPage .breadcrumbs {
  position: relative;
  color: #003476;
  font-size: 17px;
}

.NewsPage .breadcrumbs .paths {
  margin-left: 1%;
}
.NewsPage .breadcrumbs .home {
  margin-right: 4px;
}

.NewsPage > .content {
  width: 80%;
  min-height: 90vh;
  padding: 150px 0;
  margin: 0 auto;
  position: relative;
}

.NewsPage > .content .header:first-child {
  color: #003476;
  display: block;
  text-align: left;
  font-size: 2.3em;
  margin: 30px 0 30px 0;
}

.NewsPage > .content .header {
  color: #003476;
  display: block;
  text-align: left;
  font-size: 2em;
  margin: 45px 0 30px 0;
}
