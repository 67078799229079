.Collapse .body:not(.open) {
  display: none;
}

hr.solid {
  opacity: 0.3;
  margin-left: 0%;
  margin-bottom: 0px;
}

.boxed {
  background-color: rgba(243, 243, 243, 0.966);
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Collapse .section {
  margin-bottom: 27px;
}

.inv-boxed {
  border: 0px;
  text-align: left;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.inv-boxed.bordered {
  border-left: 3px solid #003476;
}

.comment {
  border: 0px;
  text-align: left;
  display: flex;
  color: rgb(60, 60, 60);
  font-size: 15px;
}

.inv-boxed:hover {
  cursor: pointer;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid;
  border-bottom: 0px;
  opacity: 0.4;
  margin-top: -1px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 0px;
  border-bottom: 12px solid;
  opacity: 1;
  margin-top: -13px;
  color: #003476;
}

.stat {
  width: 120px;
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 5px;
}

.institutionLogoContainer {
  transform: scale(0.7);
  height: 32px;
  width: 32px;
  margin-right: 10px;
  margin-top: -10px;
}

@media only screen and (max-width: 470px) {
  .inv-boxed {
    font-size: 15px;
  }
}
