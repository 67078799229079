.Organisation .menu-button {
  padding: 0px;
}

.Organisation .menu-button .fas {
  color: #ffffff !important;
}

.Organisation .menu-button:hover .fas {
  color: #00aeef !important;
}

.Organisation.Page {
  height: auto;
  position: absolute;
  background-color: #fafafa;
}

.Organisation .container {
  background-color: #fff;
  margin-top: 160px;
  min-height: 83vh;
  width: 55vw;
  margin-bottom: 80px;
  padding-top: 30px;
  padding-bottom: 80px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: Show 0.4s;
  animation-fill-mode: forwards;
  position: static;
  margin: 160px auto;
}

.Organisation .ui.container > * {
  animation: Show 0.4s;
}

.Organisation .TokenEntry,
.Organisation .Results,
.Organisation .Terms,
.Organisation .Logout,
.Organisation .error-description,
.Organisation .Check .description {
  padding-left: 40px;
  padding-right: 40px;
}

.Organisation .Header {
  background-color: #003476;
  position: absolute;
}

.Organisation .header {
  color: #003476 !important;
}

.Check > .description > h3 {
  color: #003476 !important;
}

.link-menu .fa-compass {
  color: #00aeef !important;
}

.Organisation .Steps-wrapper {
  text-align: center;
  margin-bottom: 40px;
}

.Organisation .ui.steps {
  text-align: left !important;
  width: 100%;
  border-radius: 0.1rem !important;
}

.Organisation .ui.steps .content {
  color: #353f55;
}

.Organisation .ui.steps .step:before {
  color: #a7b3a3 !important;
}

.Organisation .ui.steps .step.active,
.Organisation .ui.steps .step.active:after {
  background-color: #f5f1f1 !important;
}

.Organisation .step-description {
  padding-bottom: 45px;
}

.Organisation h2 {
  color: #003476;
}

.Organisation h2 .fas {
  color: #003476;
}

.Organisation .buttons {
  margin: 0 auto;
  text-align: center;
  margin-top: 70px;
}

.Organisation .buttons button {
  width: 130px;
}

.Organisation .buttons button:nth-child(1) {
  margin-right: 10%;
}

.Organisation .buttons button:nth-child(2) {
  margin-left: 10%;
}

.Organisation .Menu .selection {
  background-image: linear-gradient(
    #fafafa,
    #fafafa 50%,
    hsla(0, 0%, 85.5%, 0.288) 0,
    hsla(0, 0%, 85.5%, 0.288)
  ) !important;
}

.Organisation .Menu .item .fas {
  color: #003476 !important;
}

.Organisation .hiddenInput {
  display: none !important;
}

.Organisation .error-description .back-link {
  font-weight: 800;
  margin-top: 20px;
  display: block;
}

.signature input {
  text-overflow: ellipsis;
}

.popup:before {
  background-color: #003476 !important;
  border-color: #fafafa !important;
}

.popup {
  background-color: #003476 !important;
  border-color: #fafafa !important;
  color: rgb(252, 252, 246) !important;
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  .ui.action.input.signature {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1300px) {
  .Organisation .ui.container {
    width: 700px;
  }
}

@media only screen and (max-width: 1048px) {
  .Organisation .ui.container {
    width: 80vw;
  }
}

@media only screen and (min-width: 480px) and (max-width: 850px) {
  .Organisation.Page {
    background-color: #fff;
  }
  .Organisation .container {
    background-color: #fff;
    margin-top: 160px;
    min-height: 83vh;
    width: 100vw;
    margin-bottom: 80px;
    padding-top: 30px;
    padding-bottom: 80px;
    box-shadow: none;
    opacity: 0;
    animation: Show 0.4s;
    animation-fill-mode: forwards;
    position: static;
    margin: 100px auto;
  }
}

@media only screen and (max-width: 500px) {
  .Organisation .ui.container {
    margin-top: 90px;
  }
}

@media only screen and (max-width: 480px) {
  .Organisation.Page {
    background-color: #fff;
  }
  .Organisation .container {
    background-color: #fff;
    margin-top: 160px;
    min-height: 83vh;
    width: 100vw;
    margin-bottom: 80px;
    padding-top: 30px;
    padding-bottom: 80px;
    box-shadow: none;
    opacity: 0;
    animation: Show 0.4s;
    animation-fill-mode: forwards;
    position: static;
    margin: 100px auto;
  }
}

@media only screen and (max-width: 456px) {
  .Organisation .Check .description {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 390px) {
  .Organisation .Check .description {
    padding-left: 0;
    padding-right: 0;
  }
}
