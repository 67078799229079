.link-menu {
  /* display: none; */
}

.Menu {
  padding-bottom: 30px;
}

.Menu .item .header {
  color: #003476 !important;
}

.Menu,
.Menu .header {
  color: #003476 !important;
}

.Menu .description h3 {
  padding-left: 0;
  color: #353f55;
}

.Menu .selection p {
  color: black;
  opacity: 0.8;
}

.Menu .selection .item {
  margin-top: 0 !important;
  text-align: left;
}

.Menu .selection {
  position: relative !important;
  margin-top: 7% !important;
  border: 1px solid #353f55 !important;
  background-color: transparent;
  background-image: linear-gradient(
    rgb(252, 252, 246),
    rgb(252, 252, 246) 50%,
    rgba(218, 218, 218, 0.288) 50%,
    rgba(218, 218, 218, 0.288)
  ) !important;
  background-size: 100% 200% !important;
  cursor: pointer;
}

.Menu .selection:hover {
  background-position: 0 -100% !important;
  transition: background-position 0.3s;
}

.Menu .selection:hover .header {
  color: #353f55 !important;
}

.Menu .selection:hover .arrow {
  width: 12%;
  transition: all 0.2s;
}

.Menu .selection > div {
  display: block;
}

.Menu .selection:after {
  content: '';
  display: inline-block;
  vertical-align: top;
  height: 100%;
}

.Menu .item {
  margin-top: 0;
}

.Menu .item .icon {
  height: 100% !important;
  background-color: transparent;
  display: block;
  width: 10%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 40px;
  margin-left: 5px;
}

.Menu .item .icon .fas {
  position: relative;
  top: calc(50% - 18px);
}

.Menu .item .header {
  font-size: 18px !important;
  color: #003476 !important;
  padding-left: 10px;
}

.Menu .item .content {
  padding: 16px 10px !important;
  margin-left: 8% !important;
  display: inline-block;
  vertical-align: middle;
  width: 90%;
}

.Menu .item .description {
  font-size: 16px !important;
  width: 80%;
  padding-bottom: 10px;
  padding-left: 10px;
  opacity: 0.85;
}

.Menu .item .arrow {
  height: 100%;
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  background-color: transparent;
  width: 10%;
  text-align: center;
  border: none;
  cursor: pointer;
  margin-right: 4px;
}

.Menu .item .arrow .fas {
  position: relative;
  font-size: 40px;
  position: relative;
}

.Menu .item .fas {
  color: #353f55cc !important;
  transition: color 0.3s;
}

.Menu.fade-out {
  opacity: 0;
  transition: opacity 0.4s;
}

@media only screen and (max-width: 1280px) {
  .Menu .items .icon,
  .Menu .item .arrow .fas {
    font-size: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .Menu .items .icon {
    display: inline-block;
    height: auto !important;
    padding-top: 17px;
    font-size: 30px;
    margin-left: 3px;
    margin-top: 22px;
    font-size: 20px;
  }

  .Menu .items .content {
    width: 90%;
    margin: 0 !important;
  }

  .Menu .items .content .header {
    margin-left: 34px;
    padding-left: 5px;
  }

  .Menu .items .content .description {
    padding-right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .Menu .items h4 {
    font-size: 17px !important;
  }

  .Menu .items {
    padding: 0 10px;
  }

  .Menu .items .icon {
    font-size: 20px;
    margin-left: 3px;
    margin-top: 22px;
  }

  .Menu .items .content {
    width: 85%;
    margin: 0 !important;
  }

  .Menu .item .description {
    width: 90%;
  }

  .Menu .items .content .header {
    margin-left: 28px;
    font-size: 16px !important;
  }

  .Menu .items .description {
    padding-right: 5px;
  }

  .Menu .items .arrow {
    width: 16%;
    margin-right: 0;
  }
  .Menu .selection:hover .arrow {
    width: 15%;
    transition: all 0.2s;
  }

  .Menu .items .arrow .fas {
    font-size: 27px;
  }
}

@media only screen and (max-width: 320px) {
  .Menu .items .content .header {
    margin-left: 18px;
  }
}
