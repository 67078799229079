.Organisation .TokenEntry .Token {
  padding-top: 10px;
  margin-bottom: 20px;
}

.Organisation .TokenEntry .field {
  display: inline-flex;
  width: 90%;
}
.Organisation .TokenEntry .label {
  margin-bottom: 0;
}
.Form.Token input[type='text'] {
  padding: 0.6em 0.7em;
}

.Form.Token .input-group {
  margin-bottom: 0;
}

.Organisation .TokenEntry .hint {
  font-size: 13px;
  color: rgb(127, 125, 125);
}

.Organisation .TokenEntry .search-button {
  margin-top: 15px;
}

.Organisation .TokenEntry .description > h3 {
  color: #353f55;
}

.Organisation .modal .content {
  padding: 21px 30px !important;
  height: 80px;
}

.Organisation .TokenEntry .field .label {
  font-weight: normal;
}

.search-button {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

#downloadPdf {
  display: inline;
}

#downloadPdf input {
  display: none !important;
}

#downloadPrintable {
  display: inline;
}

#downloadPrintable input {
  display: none !important;
}

.modal-wrapper {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 3;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.modal {
  width: 70%;
  min-width: 500px;
  background-color: rgba(255, 255, 255, 1);
  overflow-y: auto;
  height: 85vh;
  display: block;
  margin: 0 auto;
  z-index: 3;
  position: fixed;
  left: 15%;
  top: 5vh;
}

.modal .content {
  min-height: 50vh;
  padding: 10px 40px;
  margin-top: 80px;
}

.modal .header {
  font-size: 20px;
  background-color: #003476;
  color: rgb(255, 255, 255);
  padding-left: 40px;
  position: relative;
  position: fixed !important;
  width: 70%;
  box-sizing: border-box;
  z-index: 100;
}

.modal .header h4 {
  display: inline-block;
}

.modal .header button {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 20px;
  border: 0;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 32%;
}

.modal .content table {
  border-collapse: collapse;
}

.modal .content table td {
  padding: 15px 20px;
  font-size: 16px;
}

.modal .content .side_description {
  font-size: 40px;
}

.modal #copiedNotification,
.pill-content td #copiedNotification {
  color: rgba(0, 128, 0, 0.76);
  font-size: 15px;
  text-align: left;
  height: 10px;
  opacity: 0;
  margin-top: 5px;
  padding-bottom: 10px;
  font-size: 0.7em;
}

.modal #copiedNotification.show,
.pill-content td #copiedNotification.show {
  animation: Show 0.3s;
  animation-fill-mode: forwards;
}

.modal #copiedNotification.disappeared,
.pill-content td #copiedNotification.disappeared {
  opacity: 1;
  animation: Disappear 1.8s;
  animation-fill-mode: forwards;
}

.modal .actions .left,
.modal .actions .right {
  margin-bottom: 13px;
}

.modal .actions,
.pill-content .actions {
  display: block;
  text-align: center;
}
.pill-content .actions {
  padding: 3%;
}

.pill-content .actions button {
  font-size: 14px;
  margin: 10px 10px;
  padding: 0 16px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.pill-content #downloadPdf button,
.pill-content #downloadPrintable button {
  padding: 10px 35px;
}

.pill-content {
  width: 100%;
}

.modal .actions .left button {
  margin-left: 2px;
  margin-right: 2px;
}

.modal .actions .right {
  float: right;
}

/* .pill-content tr:nth-last-child(1) {
  border-bottom: none;
} */

@media only screen and (max-width: 768px) {
  .modal .content .side_description {
    font-size: 18px !important;
    padding-right: 0px !important;
  }

  .modal .header {
    position: fixed;
    width: 100%;
    padding-left: 10px;
  }

  .modal .header button {
    right: 5%;
  }

  .modal .content {
    padding: 80px 5px;
    margin-top: 0;
  }

  .modal #closeModal {
    display: none;
  }

  .modal .actions button {
    margin-right: 3px;
    margin-left: 3px;
  }

  .modal .actions .left {
    float: none;
  }

  .modal {
    width: 100%;
    min-width: 250px;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
  }

  .modal .content .inner-td {
    width: 100%;
  }
}

@media only screen and (max-width: 386px) {
  .modal .content {
    padding: 40px 5px;
    margin-top: 40px;
  }

  .modal .content table td {
    padding: 0 3px;
  }
}
