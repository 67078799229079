.Graduate .CompletedProcess .step-description {
  padding-bottom: 25px;
}

.CompletedProcess .hidden {
  display: none;
}

.CompletedProcess #token-area {
  text-align: center;
  width: 50%;
  display: block;
  margin: 0 auto;
}

.CompletedProcess #token-area p {
  word-break: break-all;
}

.CompletedProcess .token-container {
  font-size: 20px;
  background-color: rgb(224, 224, 224);
  display: flex;
  transition: border 0.5s;
  margin: 0 auto;
  text-align: left;
  position: relative;
  max-width: 450px;
  min-height: 1em;
}

.CompletedProcess .token-container .token-text {
  flex: auto;
  padding: 15px;
  overflow: hidden;
  white-space: nowrap;
  background-color: rgb(224, 224, 224);
  border: 0;
  font-size: 16px;
  color: black;
  outline: none;
}
.CompletedProcess #token-area .or {
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 22px;
}

.CompletedProcess .token-container .token-action {
  padding: 15px 20px;
  border-top: 0px;
  background-color: rgb(53, 63, 85);
  color: rgb(252, 252, 246);
  transition: all 0.25s;
  cursor: pointer;
}

.CompletedProcess .token-container .token-action:not(:first-child) {
  margin-left: 1px;
}

.CompletedProcess .token-container .token-action:hover {
  transition: all 0.5s;
  background-color: rgba(53, 63, 85, 0.7);
}

.CompletedProcess a:not(.token-action) {
  color: #003476;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  transition: color 0.5s, border 0.5s;
}

.CompletedProcess a .fas {
  color: #003476 !important;
}

.CompletedProcess a:not(.token-action):hover {
  color: #00aeef;
  border-bottom: 1px solid #00aeef;
  transition: color 0.5s, border 0.5s;
}

.CompletedProcess .token-container .mailTemplate:hover {
  border-bottom: 0;
  cursor: default;
}

.CompletedProcess a:hover .fas {
  color: #00aeef !important;
}

.CompletedProcess .mailTemplate {
  border-bottom: 0;
}

.CompletedProcess .ui.message ul > li {
  margin-bottom: 15px;
}

.CompletedProcess #duration-container {
  margin-top: 50px;
  margin-bottom: 6%;
}

.CompletedProcess #duration-container h4 {
  width: 30%;
  border-bottom: 1px solid #353f55;
  padding-bottom: 12px;
}

.CompletedProcess .ui.message .header {
  color: #353f55;
  margin-bottom: 15px;
}

.CompletedProcess .ui.message ul {
  margin-top: 25px;
  padding-left: 15px;
  opacity: 0.9;
}

.CompletedProcess #duration-results {
  display: flex;
  align-content: center;
}
.CompletedProcess #duration-results span {
  align-self: center;
}

.CompletedProcess #duration {
  margin-left: 10px;
  font-size: 18px;
  display: inline-block;
}

.CompletedProcess .step-description {
  padding-bottom: 0;
}

.CompletedProcess #copiedNotification {
  color: rgba(0, 128, 0, 0.76);
  text-align: center;
  height: 10px;
  opacity: 0;
  margin-top: 10px;
}

.CompletedProcess #copiedNotification.show {
  animation: Show 0.3s;
  animation-fill-mode: forwards;
}

.CompletedProcess #copiedNotification.disappeared {
  opacity: 1;
  animation: Disappear 1.8s;
  animation-fill-mode: forwards;
}

@keyframes Show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Disappear {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1180px) {
  .CompletedProcess #token-area {
    width: 60%;
  }

  .CompletedProcess .token-container .token-text {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .CompletedProcess #token-area {
    width: 100%;
    margin: 0 auto;
  }

  .CompletedProcess .token-text {
    font-size: 18px;
  }

  .CompletedProcess .token-wrapper {
    font-size: 15px;
    padding: 14px 14px;
    width: calc(100% - 30px);
    overflow: auto;
    display: inline-block;
  }

  .CompletedProcess #token {
    padding: 0 10px;
  }

  .CompletedProcess .token-container .copy,
  .CompletedProcess .token-container .copy:hover,
  .CompletedProcess .token-container .mailTemplate,
  .CompletedProcess .token-container .mailTemplate:hover {
    padding: 12px 12px;
  }
}

@media only screen and (max-width: 368px) {
  .CompletedProcess #token-area {
    width: 100%;
  }

  .CompletedProcess .token-container {
    justify-content: center;
    align-items: center;
  }

  .CompletedProcess .token-container .token-text {
    font-size: 15px;
    padding-left: 5px;
    padding-right: 0;
  }
}
