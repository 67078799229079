.Header .header-content {
  font-family: 'Roboto Slab';
  color: #fff;
  opacity: 1;
  font-weight: 600;
  padding-left: 3%;
  text-align: left;
  width: 70%;
  padding-top: 63px;
}

.Header .header-content .Header .header-content a {
  color: #fff;
  font-size: 35px;
  position: relative;
}

.Header {
  height: 130px;
  width: 100%;
  background-color: #003476;
  z-index: 2;
  display: block;
  position: absolute;
  top: 0;
}

.header-border {
  position: absolute;
  top: 130px;
  width: 100%;
  height: 4px;
  background: linear-gradient(358.5deg, #00aeef 0.09%, #134a86);
}

.Header .logged_in_info {
  font-weight: 700;
  color: #353f55;
}

.Header .logged_in_info + .row {
  margin-top: 15px;
}

.Header .user-menu.mobile {
  display: none;
}

#mobile-menu {
  display: none;
}

.Header .user-menu.full {
  position: absolute;
  right: 0;
  top: 100px;
  padding-right: 10px;
}

.Header .user-menu.full #clientName {
  margin-right: 13px;
}

.Header .user-menu.full #client-name span {
  /* color: #dcdcdc; */
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.Header .user-menu.full span {
  font-size: 14px;
  color: #3a4252;
  font-weight: 700;
}

.Header .user-menu.full .fas {
  margin-right: 5px;
}

#clientName span {
  color: rgb(220, 220, 220);
}

.user-menu {
  z-index: 2;
}
.user-menu a,
.user-menu a span {
  color: #00aeef !important;
}

.logo-area {
  font-size: 35px;
  font-weight: 600;
  position: relative;
  bottom: 15px;
}

.img-logo {
  height: 55px;
  margin-right: 1px;
}

#img-minedu {
  position: relative;
  top: 10px;
}

.logo-text {
  font-size: 25px;
  color: white;
  font-weight: 500;
}

.logo-split {
  border-right: 2px solid white;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

#img-govgr {
  width: 100px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.language-switch {
  position: absolute;
  color: #fff !important;
  z-index: 3;
  width: 150px;
  text-align: right;
  top: 50px;
  right: 20px;
  font-weight: bold;
}

.language-switch button {
  color: #fff !important;
}

.language-switch button:hover {
  color: #00aeef !important;
  border-bottom: 2px solid #00aeef;
}

.language-switch button.active {
  border-bottom: 2px solid #00aeef;
  color: #00aeef !important;
}

.language-switch-mobile {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .Header .user-menu.full {
    display: none;
  }

  .Header .user-menu.mobile {
    display: block;
    position: absolute;
    top: 80px;
    right: 3px;
    padding-right: 20px;
    padding-top: 10px;
    z-index: 4;
    background-color: #003476;
  }

  .language-switch-mobile {
    display: inline-block;
  }

  .Header .menu-button {
    padding: 0px;
    text-align: center;
    cursor: pointer;
  }

  .Header .menu-button .fas {
    transition: color 0.3s;
    color: #ffffff;
    font-size: 25px;
  }

  .Header .menu-button:hover .fas {
    color: #00aeef;
    transition: color 0.3s;
  }

  #mobile-menu {
    position: absolute;
    background-color: #003476;
    width: 50%;
    right: 0;
    top: 133px;
    display: block;
    max-width: 340px;
  }

  #mobile-menu ul {
    list-style: none;
    padding-left: 0;
    text-align: center;
    min-height: 140px;
    min-width: 180px;
  }

  #mobile-menu li {
    color: #fff;
    display: block;
    padding: 10px 30px;
  }

  #mobile-menu li a {
    color: #fff;
  }

  #mobile-menu li:first-child {
    padding: 20px 30px 30px 30px;
  }

  #mobile-menu li:last-child {
    padding: 10px 30px 20px 30px;
  }

  #mobile-menu li:nth-last-child(2)::after {
    content: '';
    position: relative;
    padding: 10px;
    border-bottom: 1px solid white;
    width: 70%;
    display: block;
    margin: 0 auto;
  }

  .language-switch button.active,
  #mobile-menu .liLogout a:hover {
    color: #00aeef !important;
    border-bottom: 2px solid #00aeef;
    background: none;
    transition: color 0.3s;
  }

  #eDiplomasLogo {
    position: relative;
    display: block;
    left: 0;
    top: -30px;
    width: 200px;
  }

  #img-minedu {
    width: 230px;
    height: auto;
    margin: 1px auto;
    padding-right: 15px;
    left: 0;
    top: -27px;
  }

  .Header .logo-split {
    display: none;
  }
  /* .Header #img-govgr {
    width: 100px;
    height: auto;
    position: absolute;
    top: 30%;
    right: 1%;
  } */
}

@media screen and (max-width: 780px) {
  .Header .logo-split {
    display: none;
  }
  #eDiplomasLogo {
    position: relative;
    display: block;
    left: 0;
    top: -30px;
    width: 200px;
  }

  #img-minedu {
    width: 220px;
    height: auto;
    margin: 1px auto;
    padding-right: 15px;
    left: 0;
    top: -25px;
  }
  #mobile-menu ul {
    min-width: 150px;
    min-height: 80px;
  }
  .img-logo {
    height: 40px;
  }

  .logo-area {
    font-size: 35px;
    bottom: 5px;
  }
  .logo-text {
    font-size: 22px;
  }
}

@media only screen and (max-width: 550px) {
  /* medium phones */

  #eDiplomasLogo {
    position: relative;
    display: block;
    left: 0;
    top: -30px;
    width: 200px;
  }

  #img-minedu {
    width: 200px;
    height: auto;
    margin: 1px auto;
    padding-right: 15px;
    left: 0;
    top: -25px !important;
  }

  .logo-split {
    display: none;
  }
}

@media screen and (max-width: 300px) {
  #mobile-menu ul {
    min-width: 120px;
  }
  .Header .user-menu.mobile {
    display: block;
    position: absolute;
    right: 0;
  }

  #img-minedu {
    width: 200px;
    top: -20px;
  }
  .logo-area {
    font-size: 20px;
  }
}
