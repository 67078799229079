.link-menu > a {
  color: rgba(181, 100, 87, 0.76) !important;
}

.link-menu:hover > a {
  /* color: #B56357 !important; */
  transition: all 0.5s;
}

.link-menu {
  display: inline-block;
  position: absolute;
  top: 40px;
  right: 40px;
  color: rgba(181, 100, 87, 0.76) !important;
  font-weight: 800;
  font-size: 27px;
  text-align: center;
}

[data-tooltip]:before {
  width: 0.71428571em !important;
  height: 0.71428571em !important;
  background-color: #353f55 !important;
}

[data-tooltip]:before,
[data-tooltip]:after {
  border-color: #353f55;
  color: white;
}

[data-tooltip]:after {
  background-color: #353f55;
  min-width: 80px;
  min-height: 50px;
  word-wrap: break-word;
}

[data-tooltip]:hover:after,
[data-tooltip].show-popup:after {
  opacity: 1;
  visibility: visible !important;
  transform: translateX(-50%) scale(1) !important;
}

[data-tooltip].show-popup:before {
  opacity: 1;
  visibility: visible !important;
  transform: rotate(45deg) scale(1) !important;
}

@media only screen and (max-width: 770px) {
  [data-tooltip].show-popup:after,
  [data-tooltip].show-popup:before {
    opacity: 1;
    visibility: visible !important;
  }

  [data-tooltip]:hover:after,
  [data-tooltip].show-popup:after {
    transform: translateX(-100%) scale(1) !important;
  }

  [data-tooltip]:hover:before,
  [data-tooltip].show-popup:before {
    transform: rotate(25deg) translate(-9px, 2px) scale(1) !important;
  }
}

@media only screen and (max-width: 315px) {
  [data-tooltip].show-popup:after,
  [data-tooltip]:hover:after {
    min-width: 0;
    overflow-wrap: break-word !important;
    word-break: break-all !important;
    transform: translateX(-75%) !important;
  }

  [data-tooltip]:hover:before,
  [data-tooltip].show-popup:before {
    transform: rotate(45deg) scale(1) !important;
  }
}
