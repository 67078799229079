.Logout .ui.info.message {
  margin-top: 30px;
}

.Logout .ui.info.message ul {
  padding-left: 10px;
}

.Logout .ui.info.message ul > li {
  margin-bottom: 15px;
}

.Logout a {
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  transition: color 0.5s, border 0.5s;
}

.Logout a:hover {
  color: #00aeef !important;
  border-bottom: 1px solid #00aeef !important;
  transition: color 0.5s, border 0.5s;
}

.Logout a:hover .fas {
  color: #00aeef !important;
}

.Logout .hidden {
  display: none;
}

.Logout .back-link {
  position: relative;
  top: 12px;
}
